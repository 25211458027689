import { UserDTO, Vertical } from '@/api/meero-suite/users';
import { WorkspaceDTO } from '@/api/uam/types';
import { ContextData, SegmentEventLabel } from './segment-label/event-label';

/**
 * Segment Analytics calls
 */
export function identifyUser(segmentUser: SegmentUser) {
  if (typeof window.analytics !== 'undefined') {
    window.analytics.identify(segmentUser.userId, segmentUser);
  }
}

type PageContext = { back_from_background: boolean };
export function pageViewed(name: string, context?: PageContext) {
  if (typeof window.analytics !== 'undefined') {
    window.analytics.page(name, undefined, {
      context: { back_from_background: context?.back_from_background ?? false },
    });
  }
}

export function trackButtonClicked(
  eventName: SegmentEventLabel,
  contextData?: ContextData
) {
  if (typeof window.analytics !== 'undefined') {
    window.analytics.track(eventName, contextData);
  }
}

type IndependentEventLabel = 'User Created' | 'Album Created';
export function trackIndependentEvent(
  eventName: IndependentEventLabel,
  contextData?: ContextData
) {
  if (typeof window.analytics !== 'undefined') {
    window.analytics.track(eventName, contextData);
  }
}

/**
 * Segment types
 */
export type SegmentUser = {
  userId: string;
  createdAt: string;
  email: string;
  workspace_uuid: string;
  workspace_name: string;
  vertical: Vertical;
  membership_role: string | undefined;
  subscription_plan: string | undefined;
  isMeero: 0 | 1 | undefined;
  anonymous: 0 | 1; // 1 if the user rejected the cookie policy
  countryName: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export function getSegmentUser(
  user: UserDTO,
  workspace: WorkspaceDTO
): SegmentUser {
  return {
    userId: user.identifier,
    createdAt: user.createdAt,
    email: user.email,
    workspace_uuid: workspace.uuid,
    workspace_name: workspace.name,
    vertical: user.vertical.vertical,
    membership_role: user.roles.at(0),
    subscription_plan: undefined,
    isMeero: user.meeroAccount ? 1 : 0,
    anonymous: 0,
    countryName: user.countryCode,
    firstName: user.firstname,
    lastName: user.lastname,
    phone: user.phoneNumber,
  };
}
