import styled from '@/utils/styled';
import { colors, fontSize, getMeeSpacing } from '@/theme';
import { StyledMenu } from '../BaseMenu.styles';

export const ProfileMenuHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: ${getMeeSpacing(2.25, 2, 2)};
  border-radius: 8px 8px 0 0;
  background-color: ${colors.white};
  border: 1px solid ${colors.translucentWhite};
  border-bottom: none;
  width: 240px;
`;

export const ProfileAriaMenu = styled(StyledMenu)`
  border-radius: 0 0 8px 8px;
`;

export const MenuButtonContent = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${getMeeSpacing(2)};
  padding: ${getMeeSpacing(1, 0)};
`;

export const WorkspaceChip = styled('div')`
  background-color: ${colors.azrakBlue};
  color: ${colors.white};
  border-radius: 30px;
  padding: ${getMeeSpacing(0.25, 1.25)};
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${fontSize.xSmall};
  font-weight: 600;
`;
