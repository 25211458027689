import { useRouter } from 'next/router';
import { TwicImg } from '@twicpics/components/react';
import { useTranslation } from 'next-i18next';
import { useDialog } from '@/context/DialogContext';
import { assets } from '@/config/assets-links';
import { NavigationItem } from '@/types/Navigation/Navigation';
import { useLicence } from '@/api/meero-suite/billing';
import { trackButtonClicked } from '@/lib/analytics';

type DialogStatus = 'trial-expired' | 'feature-unavailable' | 'inactive';

export const useNavigationRestriction = () => {
  const router = useRouter();
  const { openDialog, dismissDialog } = useDialog();
  const { t } = useTranslation('navigationBar');
  const { data: licence } = useLicence();

  let dialogStatus: DialogStatus = 'inactive';
  let baseTranslationKey: string;

  const handleOpenRestrictionDialog = (navigationId: NavigationItem['id']) => {
    if (licence === undefined) {
      throw new Error('Cannot check the licence or credits, aborting.');
    }

    const { status } = licence.computed;

    if (status === 'trialing') {
      dialogStatus = 'feature-unavailable';
      baseTranslationKey = `restrictionDialog.${dialogStatus}.${navigationId}`;
    }

    if (status === 'trial-expired') {
      dialogStatus = 'trial-expired';
      baseTranslationKey = `restrictionDialog.${dialogStatus}`;
    }

    if (status === 'inactive') {
      dialogStatus = 'inactive';
      baseTranslationKey = `restrictionDialog.${dialogStatus}`;
    }

    trackButtonClicked('Pop-up Limitations Display', {
      page: router.asPath === '/' ? 'Home' : router.asPath,
      type: `${navigationId} blocked`,
      licence_status: status,
    });

    openDialog({
      headerImgSlot: (
        <TwicImg
          src={assets.cdnImageAssets.restrictionDialogHeaderImage}
          ratio="2"
        />
      ),
      variant: 'restriction',
      title: t(`${baseTranslationKey}.title`),
      message: t(`${baseTranslationKey}.message`),
      onConfirm: () => {
        void router.push('/subscription').then(dismissDialog);
        trackButtonClicked('Subscribe From Pop-up Clicked', {
          page: router.asPath === '/' ? '/home' : router.asPath,
        });
      },
      ctaButtonLabel: t('restrictionDialog.subscribeBtn'),
      onDismiss: dismissDialog,
      onClose: dismissDialog,
    });
  };

  return {
    handleOpenRestrictionDialog,
  };
};
