import ky, { KyResponse, Options } from 'ky';
import { getAppConfig } from '@/config/env';
import { customHeaders } from '@/config/custom-headers';

const { apiHost, uamBaseUrl, auth0, billingBaseUrl, applicationVersion } =
  getAppConfig();

/**
 * Meero Suite Web API
 */
export const msApi = ky.create({
  prefixUrl: apiHost,
  headers: {
    Accept: 'application/ld+json',
    [customHeaders.clientType]: 'webapp',
    [customHeaders.appVersion]: applicationVersion,
  },
  timeout: 20000,
});

type MSApiKeyWithWorkspace = [string, string | null, string | null];
export type SWRMSApiKey = MSApiKeyWithWorkspace | null;
export type GetTokenAsync = () => Promise<string>;

export const msApiGet = async <T extends object>(
  path: string,
  getTokenAsync: GetTokenAsync,
  workspaceId?: string | null,
  options?: Options
): Promise<T> => {
  const token = await getTokenAsync();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(workspaceId != null
      ? { [customHeaders.workspaceId]: workspaceId }
      : undefined),
    ...options?.headers,
  };

  const data = await msApi.get(path, { headers, ...options });

  return data.json() as T;
};

export const msApiPost = async <T extends object>(
  path: string,
  getTokenAsync: GetTokenAsync,
  workspaceId?: string | null,
  options?: Options
): Promise<T> => {
  const token = await getTokenAsync();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(workspaceId != null
      ? { [customHeaders.workspaceId]: workspaceId }
      : undefined),
    ...options?.headers,
  };

  const data = await msApi.post(path, { headers, ...options });

  // Handling the 204 No Content status
  if (data.status === 204) {
    return {} as T;
  }

  return data.json() as T;
};

export const msApiPatch = async <T extends object>(
  path: string,
  getTokenAsync: GetTokenAsync,
  workspaceId?: string | null,
  options?: Options
): Promise<T> => {
  const token = await getTokenAsync();

  const headers = {
    'Content-Type': 'application/merge-patch+json',
    Authorization: `Bearer ${token}`,
    ...(workspaceId != null
      ? { [customHeaders.workspaceId]: workspaceId }
      : undefined),
    ...options?.headers,
  };

  const data = await msApi.patch(path, { headers, ...options });

  return data.json() as T;
};

export const msApiDelete = async (
  path: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null,
  options?: Options
): Promise<KyResponse> => {
  const token = await getTokenAsync();

  const headers = {
    'Content-Type': 'application/merge-patch+json',
    Authorization: `Bearer ${token}`,
    ...(workspaceId !== null
      ? { [customHeaders.workspaceId]: workspaceId }
      : undefined),
    ...options?.headers,
  };

  return msApi.delete(path, { headers, ...options });
};

/**
 * UAM API
 */
export const uamApi = ky.create({
  prefixUrl: uamBaseUrl,
});

/**
 * AUTH0 API
 */
export const auth0Api = ky.create({
  prefixUrl: auth0.domain,
});

/**
 * BILLING API
 */
export const billingApi = ky.create({
  prefixUrl: billingBaseUrl,
});

export const billingApiGet = async <T extends object>(
  path: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null,
  options?: Options
): Promise<T | undefined> => {
  const token = await getTokenAsync();

  const headers = {
    Authorization: `Bearer ${token}`,
    [customHeaders.workspaceId]: workspaceId ?? 'missing_ws_id',
    ...options?.headers,
  };

  const data = await billingApi.get(path, { headers, ...options });

  // Making sure we don't parse an empty payload (/management-link can return a 204)
  if (data.status === 204) return undefined;

  return data.json() as T;
};

export const billingApiPost = async <T extends object>(
  path: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null,
  options: Options
): Promise<T> => {
  const token = await getTokenAsync();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(workspaceId != null
      ? { [customHeaders.workspaceId]: workspaceId }
      : undefined),
    ...options.headers,
  };
  const data = await billingApi.post(path, { headers, ...options });

  return data.json as T;
};

/**
 * Listing Generation API
 */
export const listingGenApi = ky.create({
  prefixUrl: 'https://lg.preprod.propershot.com',
});

export const listingGenApiGet = async <T extends object>(
  path: string,
  getTokenAsync: GetTokenAsync,
  options?: Options
): Promise<T> => {
  const token = await getTokenAsync();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...options?.headers,
  };

  const data = await listingGenApi.get(path, { headers, ...options });

  return data.json() as T;
};
