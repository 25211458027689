import { CSSObject, styled as MUIStyled, Theme } from '@mui/material/styles';
import { drawerClasses } from '@mui/material/Drawer';
import styled, { css } from '@/utils/styled';
import { breakpoints, colors, getMeeSpacing } from '@/theme';
import { Box, MenuIcon, MuiDrawer } from '@/components/common';
import { IconButton, Typography } from '@/components/common';
import ProperShotIcon from '@/components/icons/ProperShotIcon';

export const MENU_WIDTH_MAX = 264;
export const MENU_WIDTH_CLOSED = 54;
export const MENU_TIMING_FUNCTION = 'cubic-bezier(0.4, 0, 0.6, 1)';
export const MENU_SPEED = '300ms';
export const MENU_TEXT_DELAY = '200ms';

export const FORCE_SMALL_NAVBAR_BELOW = breakpoints.md;

export const LogoContainer = styled('div')<{ $isMenuOpen: boolean }>`
  opacity: ${({ $isMenuOpen }) => ($isMenuOpen ? '1' : '0')};
  padding-top: 2px;
  transition: ${({ $isMenuOpen }) =>
    $isMenuOpen
      ? `opacity 100ms ${MENU_TIMING_FUNCTION} ${MENU_TEXT_DELAY}`
      : `opacity 100ms ${MENU_TIMING_FUNCTION}`};
`;

const openedMixin = (theme: Theme): CSSObject => ({
  width: MENU_WIDTH_MAX,
  transition: theme.transitions.create('width', {
    easing: MENU_TIMING_FUNCTION,
    duration: MENU_SPEED,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: MENU_WIDTH_CLOSED,
  transition: theme.transitions.create('width', {
    easing: MENU_TIMING_FUNCTION,
    duration: MENU_SPEED,
  }),
});

const DrawerHeader = MUIStyled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export const Drawer = MUIStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: MENU_WIDTH_MAX,
  flexShrink: 0,
  boxSizing: 'border-box',
  overflowX: 'hidden',
  ...(open === true
    ? {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
          borderRight: 'unset',
          overflowY: 'unset',
          ...openedMixin(theme),
        },
      }
    : {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': {
          borderRight: 'unset',
          overflowY: 'unset',
          ...closedMixin(theme),
        },
      }),
}));

export const DrawerContainer = styled('div')<{ $isMenuOpen: boolean }>`
  padding: ${({ $isMenuOpen }) =>
    $isMenuOpen ? getMeeSpacing(2, 3) : getMeeSpacing(2, 1, 3)};
  transition: padding ${MENU_SPEED} ${MENU_TIMING_FUNCTION};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome + Safari
  }

  @media only screen and (min-width: ${FORCE_SMALL_NAVBAR_BELOW}px) {
    &:hover {
      svg {
        &[data-icon='menu'] {
          opacity: 1;
        }

        &[data-icon='propershot'] {
          opacity: 0;
        }
      }
    }
  }
`;

export const Container = styled(Box)<{ $isHidden: boolean }>`
  ${({ $isHidden }) =>
    $isHidden &&
    css`
      display: none;
    `}
  user-select: none;

  .${drawerClasses.paper}::-webkit-scrollbar {
    width: 0;
  }
`;

export const DrawerHeaderCustom = styled(DrawerHeader)<{
  $isMenuOpen: boolean;
}>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding-left: ${({ $isMenuOpen }) => ($isMenuOpen ? getMeeSpacing(1) : 0)};
  transition: padding 100ms ${MENU_TIMING_FUNCTION};
`;

export const AppVersionContainer = styled(Typography)<{ $isOpened: boolean }>`
  position: absolute;
  bottom: ${getMeeSpacing(1)};
  left: ${getMeeSpacing(3)};
  color: ${colors.hydrargyrum};
  opacity: ${({ $isOpened }) => ($isOpened ? '1' : '0')};
  transition: opacity ${MENU_SPEED} ${MENU_TIMING_FUNCTION};
  white-space: nowrap;
`;

export const ToggleMenuIcon = styled(IconButton)`
  font-size: 20px;
  border-radius: 8px;
  color: ${colors.miracle};
  pointer-events: none;

  .MuiTouchRipple-child {
    border-radius: 8px !important;
  }

  @media only screen and (min-width: ${FORCE_SMALL_NAVBAR_BELOW}px) {
    pointer-events: auto;
  }
`;

export const AppearingMenuIcon = styled(MenuIcon)`
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const FadingMeeroRealtorsIcon = styled(ProperShotIcon)`
  transition: opacity 0.2s ease-in-out;
`;

export const DrawerBody = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(5)};
  padding: ${getMeeSpacing(3, 0, 4.5)};
  flex-grow: 1;
`;

export const NavigationItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 42px;
`;

export const Group = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(0.5)};
`;

export const GroupTitle = styled(Typography)<{ $isOpened: boolean }>`
  color: ${colors.miracle};
  opacity: ${({ $isOpened }) => ($isOpened ? '1' : '0')};
  transition: opacity ${MENU_SPEED} ${MENU_TIMING_FUNCTION};
`;
