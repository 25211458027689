import { useTranslation } from 'next-i18next';
import {
  BrandingWatermarkIcon,
  DashboardIcon,
  HomeIcon,
  PermMediaIcon,
  SupervisorAccountIcon,
} from '@/components/common';
import { NavigationGroup } from '@/types/Navigation/Navigation';
import { useUserWorkspaceContext } from '@/context/UserWorkspaceContext';
import { useLicence } from '@/api/meero-suite/billing';

export const useNavigationItems = () => {
  const { t } = useTranslation('navigationBar');

  const { permissions, enabledFeatures } = useUserWorkspaceContext();

  const { data: licence } = useLicence();

  const showRequireSubscription = licence?.computed.isPremium === false;

  const defaultGroup: NavigationGroup = {
    id: 'default',
    group: false,
    links: [
      {
        id: 'home',
        text: t('home'),
        icon: <HomeIcon fontSize="inherit" />,
        path: '/',
        tooltip: t('home'),
      },
      {
        id: 'library',
        text: t('library'),
        icon: <PermMediaIcon fontSize="inherit" />,
        path: '/library',
        tooltip: t('library'),
      },
    ],
  };

  const managementGroup: NavigationGroup = {
    id: 'management',
    group: true,
    title: t('usersManagement.title'),
    links: [
      {
        id: 'users',
        text: t('usersManagement.users'),
        icon: <SupervisorAccountIcon fontSize="inherit" />,
        path: '/users',
        tooltip: t('usersManagement.users'),
        requireSubscription: showRequireSubscription,
        isAllowed: permissions.has('user:read:all'),
        disabledTooltip: t('usersManagement.disabledMessage'),
      },
      {
        id: 'watermark',
        text: t('usersManagement.watermark'),
        icon: <BrandingWatermarkIcon fontSize="inherit" />,
        path: '/watermark',
        requireSubscription: showRequireSubscription,
        tooltip: t('usersManagement.watermark'),
        isAllowed: permissions.has('watermark:config:write'),
      },
      {
        id: 'overview',
        text: t('cameraApp.overview'),
        icon: <DashboardIcon fontSize="inherit" />,
        path: '/app-overview',
        tooltip: t('cameraApp.overview'),
        isAllowed:
          permissions.has('looker:read') && enabledFeatures.has('dashboard'),
      },
    ],
  };

  return [defaultGroup, managementGroup];
};
