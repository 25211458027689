import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { WorkspaceSwitchModal } from '@/components/common/WorkspaceSwitchModal/WorkspaceSwitchModal';
import { WorkspaceDTO } from '@/api/uam/types';
import { UserPermission } from '@/permissions';
import { getPermissionsFromToken } from '@/api/auth0/token';
import { useLocalStorage } from '@/hooks/localStorage/useLocalStorage';
import {
  getDisabledFeatures,
  getEnabledFeatures,
  WorkspaceFeatures,
} from '@/config/feature-toggles';

type PermissionControl = {
  has: (permission: UserPermission) => boolean;
  hasOneOf: (permissions: UserPermission[]) => boolean;
};

export interface UserWorkspaceState {
  experimentalFeatures: boolean;
  toggleExperimentalFeatures: () => void;
  setAuth0Token: (token: string) => void;
  currentWorkspaceId: string | null;
  setCurrentWorkspaceId: (workspaceId: string | null) => void;
  handleSwitchWorkspace: (newWorkspace: WorkspaceDTO) => Promise<void>;
  permissions: PermissionControl;
  enabledFeatures: WorkspaceFeatures;
  isCreatePropertyDisabled: boolean;
}

export const UserWorkspaceContext = createContext<
  UserWorkspaceState | undefined
>(undefined);

export type UserProviderProps = {
  children?: ReactNode;
};

export const UserWorkspaceProvider = ({ children }: UserProviderProps) => {
  const [experimentalFeatures, toggleExperimentalFeatures] = useReducer(
    (state: boolean) => !state,
    false
  );
  const [auth0Token, setAuth0Token] = useState<string | null>(null);
  const [currentWorkspaceId, setCurrentWorkspaceId] = useLocalStorage<
    string | null
  >('ms-w-id', null);
  const [newWorspaceName, setNewWorspaceName] = useState<string>();

  const router = useRouter();

  async function handleSwitchWorkspace(newWorkspace: WorkspaceDTO) {
    setNewWorspaceName(newWorkspace.name);

    await new Promise((res) => setTimeout(res, 1500));

    void router
      .push('/')
      .then(() => setCurrentWorkspaceId(newWorkspace.uuid))
      .then(() => setTimeout(() => setNewWorspaceName(undefined), 500));
  }

  const workspacePermissions = useMemo(() => {
    if (auth0Token === null || currentWorkspaceId === null) return [];

    return getPermissionsFromToken(auth0Token, currentWorkspaceId);
  }, [auth0Token, currentWorkspaceId]);

  return (
    <UserWorkspaceContext.Provider
      value={{
        setAuth0Token,
        currentWorkspaceId,
        setCurrentWorkspaceId,
        handleSwitchWorkspace,
        experimentalFeatures,
        toggleExperimentalFeatures,
        permissions: createAccessControl(workspacePermissions),
        enabledFeatures: getEnabledFeatures(currentWorkspaceId),
        isCreatePropertyDisabled:
          getDisabledFeatures(currentWorkspaceId).has('create-property'),
      }}
    >
      <WorkspaceSwitchModal
        isOpen={Boolean(newWorspaceName)}
        newWorkspaceName={newWorspaceName}
      />
      <>{children}</>
    </UserWorkspaceContext.Provider>
  );
};

export const useUserWorkspaceContext = () => {
  const context = useContext(UserWorkspaceContext);
  if (context === undefined) {
    throw new Error(
      'useUserWorkspaceContext must be used within the UserProvider'
    );
  }

  return context;
};

function createAccessControl(workspacePermissions: UserPermission[]) {
  return {
    has: (permission: UserPermission) =>
      workspacePermissions.includes(permission),
    hasOneOf: (permissions: UserPermission[]) =>
      workspacePermissions.some((perm) => permissions.includes(perm)),
  };
}
