import { Trans, useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { Box, Chip, CloseIcon, Typography } from '@/components/common';
import { ButtonMeero } from '@/components/common/ButtonMeero/ButtonMeero';
import { colors, Device } from '@/theme';
import {
  CloseDialogWrapper,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogMedia,
  DialogText,
  StyledCtaButton,
  StyledDialog,
} from './Dialog.styles';

type DialogProps = {
  isOpen: boolean;
  children?: ReactNode;
} & DialogConfig;

export type DialogConfig = {
  variant?: 'destructive' | 'default' | 'restriction';
  headerIcon?: ReactNode;
  headerImgSlot?: ReactNode;
  title: string;
  message?: string;
  onConfirm?: () => void;
  confirmButtonLabel?: string;
  disableConfirm?: boolean;
  ctaButtonLabel?: string;
  onDismiss?: () => void;
  onClose?: () => void;
  dismissButtonLabel?: string;
  maxWidth?: Device;
};

/**
 * A quite universal dialog component that can be used in various parts of the app.
 */
export const Dialog = ({
  headerIcon,
  headerImgSlot,
  title,
  message,
  confirmButtonLabel,
  dismissButtonLabel,
  ctaButtonLabel,
  disableConfirm = false,
  isOpen,
  onConfirm,
  onDismiss,
  onClose,
  variant = 'default',
  maxWidth = 'sm',
  children,
}: DialogProps) => {
  const { t } = useTranslation('common');

  const showActions = onConfirm !== undefined || onDismiss !== undefined;

  return (
    <StyledDialog open={isOpen} onClose={onClose} maxWidth={maxWidth}>
      {onClose !== undefined ? (
        <CloseDialogWrapper onClick={onClose}>
          <CloseIcon />
        </CloseDialogWrapper>
      ) : null}
      <>{headerImgSlot}</>
      <DialogContent
        justifyContent="center"
        minWidth="50ch"
        padding={variant === 'restriction' ? 4 : 5.5}
      >
        <DialogHeader>
          {headerIcon}
          <Typography variant="h4" fontWeight="bold">
            {title}
          </Typography>
        </DialogHeader>
        {message !== undefined && (
          <Typography color="textSecondary">
            <Trans>{message}</Trans>
          </Typography>
        )}
        {children}
        {variant === 'restriction' && (
          <DialogActions>
            <StyledCtaButton
              $variant="primary"
              $size="large"
              onPress={onConfirm}
            >
              <Typography fontWeight={700}>{ctaButtonLabel}</Typography>
            </StyledCtaButton>
          </DialogActions>
        )}
        {showActions && variant !== 'restriction' && (
          <DialogActions>
            {onDismiss !== undefined && (
              <ButtonMeero
                type="button"
                onClick={onDismiss}
                $type="secondary"
                $size="medium"
              >
                {dismissButtonLabel ?? t('confirmDialog.dismissButton')}
              </ButtonMeero>
            )}
            {onConfirm !== undefined && (
              <ButtonMeero
                $type={variant === 'destructive' ? 'warning' : 'primary'}
                onClick={onConfirm}
                $size="medium"
                disabled={disableConfirm}
              >
                {confirmButtonLabel ?? t('confirmDialog.confirmButton')}
              </ButtonMeero>
            )}
          </DialogActions>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

interface MediaDialogProps {
  isOpen: boolean;
  chipLabel: string;
  titleIntro: string;
  title: string;
  onConfirm: () => void;
  confirmButtonLabel?: string;
  mediaSlot: ReactNode;
  children: ReactNode;
}
/**
 * A more specific Dialog component that embeds a slot to display a media.
 */
export const MediaDialog = ({
  isOpen,
  chipLabel,
  titleIntro,
  title,
  onConfirm,
  confirmButtonLabel,
  mediaSlot,
  children,
}: MediaDialogProps) => {
  const { t } = useTranslation('common');

  return (
    <StyledDialog open={isOpen} transitionDuration={{ enter: 1500, exit: 500 }}>
      <DialogMedia>{mediaSlot}</DialogMedia>
      <DialogContent justifyContent="left" maxWidth="52ch">
        <Box display="inline-flex" justifyContent="inherit">
          <Chip
            size="small"
            label={chipLabel.toUpperCase()}
            sx={{
              color: colors.hollyGreen,
              backgroundColor: colors.frostyMint,
            }}
          />
        </Box>
        <DialogText>
          <Box>
            <Typography variant="h4" textAlign="left">
              {titleIntro}
              <br />
              <strong>{title}</strong>
            </Typography>
          </Box>
          {children}
        </DialogText>
        <DialogActions>
          <ButtonMeero $type="primary" onClick={onConfirm} $size="small">
            {confirmButtonLabel ?? t('confirmDialog.confirmButton')}
          </ButtonMeero>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};
