import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { getAppConfig } from '@/config/env';

export const Hotjar = () => {
  useEffect(() => {
    const { hotjarId } = getAppConfig();

    if (process.env.NODE_ENV === 'development') {
      console.info('Hotjar is disabled (local env)');

      return;
    }

    if (hotjarId === undefined) {
      console.info('Hotjar is disabled');

      return;
    }

    hotjar.initialize(parseInt(hotjarId, 10), 1);
  }, []);

  return null;
};
