import { z } from 'zod';

export const WorkspaceSchema = z.object({
  uuid: z.string(), // .uuid() is not used because we had some uuid in v8 that threw errors
  name: z.string(),
});

export type WorkspaceDTO = z.infer<typeof WorkspaceSchema>;
export type WorkspacesListDTO = Array<WorkspaceDTO>;

export type UAMApiError = {
  errorMessage: string;
  errorType: string;
  requestId: string;
  stackTrace: Array<string>;
};
