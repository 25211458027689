import { colors, fonts } from '@/theme';
import {
  MENU_SPEED,
  MENU_TIMING_FUNCTION,
  MENU_WIDTH_MAX,
} from '@/components/templates/Navigation/NavigationBar/NavigationBar.styles';
import styled from '@/utils/styled';

export const Root = styled('div')`
  //overflow: hidden;
`;

export const GLOBAL_PADDING = '4.4vw';

export const Main = styled('main')<{ $isOpen: boolean }>`
  position: relative;
  background-color: ${colors.white};
  font-family: ${fonts.primary};
  margin-left: ${({ $isOpen }) => ($isOpen ? MENU_WIDTH_MAX : 56)}px;
  transition: margin ${MENU_TIMING_FUNCTION} ${MENU_SPEED};
  min-height: 100dvh;
`;
