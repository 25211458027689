import { useTranslation } from 'next-i18next';
import { Box, CircularProgress, Typography } from '@/components/common';
import { StyledBox, StyledModal } from './WorkspaceSwitchModal.styles';

interface WorkspaceSwitchModalProps {
  isOpen: boolean;
  newWorkspaceName: string | undefined;
}

export function WorkspaceSwitchModal({
  isOpen,
  newWorkspaceName,
}: WorkspaceSwitchModalProps) {
  const { t } = useTranslation('common');

  return (
    <StyledModal open={isOpen}>
      <StyledBox display="flex" flexDirection="column" rowGap={3}>
        <Box display="flex" justifyContent="center">
          <CircularProgress color="inherit" />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Typography variant="h5" color="white" component="span">
            {t('workspace.switch')}
          </Typography>
          <Typography variant="h5" color="primary" component="span">
            {newWorkspaceName}
          </Typography>
        </Box>
      </StyledBox>
    </StyledModal>
  );
}
