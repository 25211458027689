import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { TwicInstall } from '@twicpics/components/react';
import { useRouter } from 'next/router';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { EmotionCache } from '@emotion/react';
import { GoogleTagManager } from '@next/third-parties/google';
import { Hotjar } from '@/components/services/Hotjar/Hotjar';
import { SegmentAnalytics } from '@/components/services/SegmentAnalytics/SegmentAnalytics';
import { SnackbarProvider } from '@/components/services/SnackbarProvider/SnackbarProvider';
import { DialogProvider } from '@/context/DialogContext';
import { UsersImportProvider } from '@/context/UsersImportContext';
import { UserWorkspaceProvider } from '@/context/UserWorkspaceContext';
import { Navigation } from '@/components/templates/Navigation/Navigation';
import { getAppConfig } from '@/config/env';
import { trackButtonClicked } from '@/lib/analytics';

import { poppins } from '@/styles/fonts';
import theme from '../theme';

import '@twicpics/components/style.css';
import '../styles/globals.css';

const MyApp = ({
  Component,
  pageProps,
  emotionCache,
}: AppProps & { emotionCache?: EmotionCache }) => {
  const { auth0, twicPicsDomain } = getAppConfig();
  const { push } = useRouter();

  return (
    <AppCacheProvider emotionCache={emotionCache}>
      <style jsx global>{`
        // This allows us to keep setting the default font in globals.css
        :root {
          --font-primary: ${poppins.style.fontFamily};
        }
      `}</style>
      <TwicInstall domain={twicPicsDomain} />
      <SegmentAnalytics />
      <GoogleTagManager gtmId="GTM-NFNC7BGV" />
      <Hotjar />
      <ThemeProvider theme={theme}>
        <SnackbarProvider />
        <Auth0Provider
          domain={auth0.domain}
          clientId={auth0.clientId}
          authorizationParams={{
            audience: auth0.audience,
            redirect_uri: auth0.redirectUri,
          }}
          onRedirectCallback={(state) => {
            trackButtonClicked('Login CTA Clicked', { page: 'Login' });
            void push(state?.returnTo ?? '/');
          }}
        >
          <UserWorkspaceProvider>
            <UsersImportProvider>
              <DialogProvider>
                <Navigation>
                  <Component {...pageProps} />
                </Navigation>
              </DialogProvider>
            </UsersImportProvider>
          </UserWorkspaceProvider>
        </Auth0Provider>
      </ThemeProvider>
    </AppCacheProvider>
  );
};

export default appWithTranslation(MyApp);
