import { MarkEmailUnreadOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { Button, ButtonIconOnly } from '@/components/common/Button/Button';
import { Box, CloseIcon } from '@/components/common';
import { useDialog } from '@/context/DialogContext';
import { UserInvitationDTO } from '@/api/meero-suite/users';
import { BannerContainer } from './InvitationBanner.styles';

type InvitationBannerProps = {
  invitation: UserInvitationDTO;
  onDismiss: () => void;
};

export function InvitationBanner({
  invitation,
  onDismiss,
}: InvitationBannerProps) {
  const { t } = useTranslation('common');

  const { openDialog, dismissDialog } = useDialog();

  return (
    <BannerContainer>
      <Box component="span" display="flex" gap={1} color="white">
        <MarkEmailUnreadOutlined />
        <Typography variant="body1" color="white" fontWeight={600}>
          {t('invitation.banner.title')}
        </Typography>
      </Box>
      <Box
        component="span"
        position="absolute"
        right={16}
        display="flex"
        gap={1}
        color="white"
        alignItems="center"
      >
        <Button
          $variant="secondary-white"
          onPress={() =>
            openDialog({
              title: t('invitation.dialog.title', {
                workspaceName: invitation.accountName,
              }),
              message: t('invitation.dialog.message', {
                senderEmail: invitation.invitedByEmail,
                senderName: `${invitation.invitedByFirstname} ${invitation.invitedByLastname}`,
                workspaceName: invitation.accountName,
              }),
              confirmButtonLabel: t('invitation.dialog.accept'),
              onConfirm: () => {
                alert('onConfirm');
                dismissDialog();
              },
              dismissButtonLabel: t('invitation.dialog.decline'),
              onDismiss: () => {
                alert('onDismiss');
                dismissDialog();
              },
              onClose: dismissDialog,
            })
          }
        >
          <Typography
            variant="body1"
            color="white"
            lineHeight="24px"
            paddingX={3}
            fontWeight={600}
          >
            {t('invitation.banner.view')}
          </Typography>
        </Button>
        <ButtonIconOnly
          $variant="secondary-white"
          style={{ border: 'none' }}
          onPress={onDismiss}
        >
          <Box height={24} display="flex" alignItems="center">
            <CloseIcon fontSize="small" color="inherit" />
          </Box>
        </ButtonIconOnly>
      </Box>
    </BannerContainer>
  );
}
