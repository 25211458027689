import { Box, Dialog } from '@/components/common';
import { breakpoints, colors, getMeeSpacing } from '@/theme';
import styled from '@/utils/styled';
import { Button } from '../Button/Button';

export const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: ${getMeeSpacing(1.5)};
    background-color: ${colors.white};
  }
`;

export const DialogContent = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: ${getMeeSpacing(1.5)};
`;

export const DialogHeader = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: ${getMeeSpacing(3)};
`;

export const DialogText = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(1)};
`;

export const DialogActions = styled('div')`
  padding-top: ${getMeeSpacing(2)};
  display: inline-flex;
  column-gap: ${getMeeSpacing(1)};
  justify-content: inherit;
  width: 100%;
`;

export const DialogMedia = styled('div')`
  @media screen and (min-width: ${breakpoints.md}px) {
    width: 321px;
  }
`;

export const StyledCtaButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getMeeSpacing(2.5)};
`;

export const CloseDialogWrapper = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  padding: ${getMeeSpacing(2.5)};
  margin: ${getMeeSpacing(2)};
  top: 0;
  right: 0;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: ${colors.whiteSmoke};
  }
`;
