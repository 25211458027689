import { Menu, MenuItem, Popover } from 'react-aria-components';
import { colors, getMeeSpacing } from '@/theme';
import styled from '@/utils/styled';
import { Button } from '../Button/Button';

export const StyledDropdownButton = styled(Button)`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: ${getMeeSpacing(1)};
  white-space: nowrap;
`;

export const StyledMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  row-gap: ${getMeeSpacing(1)};
  padding: ${getMeeSpacing(1)};
  min-width: 200px;
  background-color: ${colors.white};
  border: 1px solid ${colors.translucentWhite};
  outline: none;

  .react-aria-Separator {
    &:not([aria-orientation='vertical']) {
      height: 1px;
      width: calc(100% - ${getMeeSpacing(1)});
      margin: auto;
      background-color: ${colors.translucentWhite};
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: ${getMeeSpacing(1)};
  border-radius: 8px;
  display: flex;
  align-items: center;
  column-gap: ${getMeeSpacing(1)};
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  cursor: pointer;
  outline: none;

  &[data-focused] {
    background: ${colors.crystalBell};
  }

  &[data-focus-visible] {
    outline: 2px solid ${colors.azrakBlue};
    outline-offset: -1px;
  }

  &[data-disabled] {
    color: ${colors.translucentWhite};
    cursor: default;
  }
`;

export const StyledPopover = styled(Popover)`
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.1);
  outline: none;

  .react-aria-Popover[data-trigger='SubmenuTrigger'][data-placement='right'] {
    margin-left: -5px;
  }

  .react-aria-Popover[data-trigger='SubmenuTrigger'][data-placement='left'] {
    margin-right: -5px;
  }

  &[data-entering] {
    animation: slide-in 300ms;
  }

  &[data-exiting] {
    animation: slide-out 150ms;
  }

  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slide-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
`;

export const StatusContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.infinity};
`;
