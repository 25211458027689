import styled from '@/utils/styled';
import { colors, getMeeSpacing } from '@/theme';
import { IconButton } from '@/components/common';

export const BannerContainer = styled('div')<{ $isMenuOpen: boolean }>`
  display: ${({ $isMenuOpen }) => ($isMenuOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: stretch;
  gap: ${getMeeSpacing(2)};
  padding: ${getMeeSpacing(2)};
  margin-bottom: ${getMeeSpacing(3.5)};
  border: 1px solid ${colors.translucentWhite};
  border-radius: 12px;
  background-color: ${colors.white};
  overflow: hidden;
`;

export const BannerHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  gap: ${getMeeSpacing(1.5)};
`;

export const WorkspacePropertyCredits = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${getMeeSpacing(0.75)};
`;

export const PropershotIconContainer = styled('div')<{
  $hasNoCredit: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $hasNoCredit: $isNoPropertiesLeft }) =>
    $isNoPropertiesLeft ? colors.ruralRed : colors.primary};
  width: 30px;
  height: 30px;
  border-radius: 5px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const FreeTrialIndicator = styled(IconButton)<{ $isMenuOpen: boolean }>`
  display: ${({ $isMenuOpen }) => (!$isMenuOpen ? 'flex' : 'none')};
  color: ${colors.white};
  border-color: ${colors.primary};
  background-color: ${colors.primary};
  border-radius: 6px;
  margin-bottom: ${getMeeSpacing(3.5)};

  &:hover {
    background-color: ${colors.primary};
  }
`;

export const StatusTag = styled('span')`
  padding: 2px 10px;
  border-radius: 16px;
  background-color: ${colors.translucentWhite};
  color: ${colors.infinity};
  display: flex;
  align-items: center;
  gap: ${getMeeSpacing(0.5)};
  font-size: 16px;
  overflow: hidden;

  &[data-status='trial-expired'] {
    background-color: ${colors.cherubic};
    color: ${colors.ruralRed};
  }

  &[data-status='active'] {
    background-color: ${colors.moonWhite};
    color: ${colors.primary};
  }

  &[data-status='cancelled'] {
    background-color: ${colors.moonWhite};
    color: ${colors.primary};
  }

  span {
    text-transform: lowercase;
  }

  span::first-letter {
    text-transform: uppercase;
  }
`;
