import { SvgIconProps } from '@mui/material/SvgIcon';
import { colors } from '@/theme';

const ProperShotIcon = (props: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 68 64"
      role="img"
      fill={colors.primary}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5126 49.6549C28.3769 49.6549 40.9358 36.33 33.1302 36.33C25.841 36.33 14.7598 41.6617 7.25017 45.7212V57.615C7.25316 59.1537 7.80533 60.6396 8.80529 61.8004C9.80525 62.9608 11.1859 63.718 12.6938 63.9326V64H54.3845V63.9326C55.8921 63.7177 57.2728 62.9604 58.2727 61.8C59.2726 60.6396 59.8247 59.1537 59.8279 57.615V45.6152C53.9662 47.3594 45.1461 49.6549 38.5126 49.6549Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.3247 32.0273L59.8186 25.0924V10.1962C59.8171 9.35599 59.6516 8.52435 59.3318 7.74876C59.0119 6.97312 58.5439 6.26878 57.9547 5.6759C57.3655 5.08301 56.6666 4.6132 55.8979 4.29328C55.1287 3.97336 54.3051 3.80962 53.4736 3.81138H42.5967C42.23 3.81073 41.8669 3.8852 41.5294 4.03023C41.1918 4.17526 40.8871 4.3879 40.6333 4.65528L38.1409 2.00017C37.5466 1.36823 36.8312 0.865024 36.0383 0.521163C35.2453 0.177303 34.3914 0 33.5285 0C32.6656 0 31.8117 0.177303 31.0188 0.521163C30.2259 0.865024 29.5104 1.36823 28.916 2.00017L0.742966 32.0273C0.497742 32.2881 0.305951 32.595 0.178629 32.931C0.0513079 33.2668 -0.00902999 33.6248 0.00109182 33.9843C0.01121 34.3435 0.0915859 34.6976 0.237593 35.0257C0.383597 35.3535 0.592351 35.6492 0.85184 35.8951C1.29831 36.3184 1.87266 36.5781 2.4826 36.6318C3.09254 36.6855 3.7027 36.5305 4.21496 36.1919C4.9585 35.6968 5.69273 35.1894 6.45881 34.7322L7.25547 34.2584C16.4513 29.4447 31.1332 22.7325 41.5106 22.7325C51.9746 22.7325 35.8266 36.3769 46.3382 36.3769C50.7104 36.3769 56.0057 35.2807 59.8229 34.1228L61.4971 35.2125C61.9219 35.4887 62.3254 35.7974 62.7303 36.1017C63.2442 36.4858 63.8749 36.6754 64.5128 36.6379C65.1506 36.6001 65.7554 36.3372 66.2209 35.8951C66.4801 35.6488 66.6886 35.3528 66.8339 35.0246C66.9796 34.6965 67.0596 34.3424 67.069 33.9829C67.0787 33.6234 67.0178 33.2657 66.8901 32.93C66.7625 32.5943 66.5703 32.2878 66.3247 32.0273Z"
      />
    </svg>
  );
};

export default ProperShotIcon;
