import { breakpoints, colors } from '@/theme';
import styled from '@/utils/styled';

export const BannerContainer = styled('div')`
  position: absolute;
  background-color: ${colors.secondary};
  transform: translateY(-100%);
  animation: slideIn 0.4s ease-in forwards;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  @media screen and (min-width: ${breakpoints.sm}px) {
    justify-content: center;
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;
