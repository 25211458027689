import { useState } from 'react';

export function useLocalStorage<T>(key: string, initialValue: T | (() => T)) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue instanceof Function ? initialValue() : initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      const val = item !== null ? (JSON.parse(item) as T) : initialValue;

      return val instanceof Function ? val() : val;
    } catch (error) {
      console.log(error);

      return initialValue instanceof Function ? initialValue() : initialValue;
    }
  });

  function setValue(value: T | ((state: T) => T)) {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error(error);
    }
  }

  return [storedValue, setValue] as const;
}
