import { jwtDecode } from 'jwt-decode';
import { UserPermission } from '@/permissions';

type Auth0TokenPermissions = {
  [key: string]: Array<UserPermission> | undefined;
};

export interface Auth0Token {
  'https://www.meero.com/permissions': Auth0TokenPermissions;
  'https://www.meero.com/current_user': string;
  'https://www.meero.com/actor_tracking': string;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
}

export function decodeAuth0Token(token: string) {
  try {
    // + TODO validate token format with Zod
    return jwtDecode<Auth0Token>(token);
  } catch (err) {
    console.error(err);
  }

  return null;
}

export function getPermissionsFromToken(
  token: string,
  workspaceId: string
): Array<UserPermission> {
  const allPermissions =
    decodeAuth0Token(token)?.['https://www.meero.com/permissions'];

  if (allPermissions == null) return [];

  const globalPerms = allPermissions.global ?? [];
  const workspacePerms = allPermissions[workspaceId] ?? [];

  return [...globalPerms, ...workspacePerms];
}
