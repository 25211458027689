/**
 * Returns `true` if the navigation link should be shown as active, `false` otherwise.
 * @param linkPath the pathname of the link we want to know whether it is active.
 * @param currentPath the current pathname of the page visited (router.pathname for instance).
 */

export const isLinkActive = (linkPath: string, currentPath: string) => {
  if (linkPath === '/') return currentPath === '/';
  if (linkPath === '/_future') return currentPath === '/_future';

  return currentPath === linkPath || currentPath.startsWith(`${linkPath}/`);
};
