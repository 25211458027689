import { forwardRef } from 'react';
import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';
import { Fade, ListItemText } from '@/components/common';
import { NavigationItem } from '@/types/Navigation/Navigation';
import PremiumIcon from '@/components/icons/PremiumIcon';
import {
  Link,
  ListItemContainer,
  ListItemIconCustom,
  NewFeatureChip,
  NewFeatureDotIcon,
  PremiumCircle,
  TooltipCustom,
} from './NavigationLink.styles';

export type LinkVariant = 'default' | 'active' | 'disabled' | 'restricted';

interface NavigationLinkProps {
  link: NavigationItem;
  variant: LinkVariant;
  isMenuOpen: boolean;
  // href and onClick must be forwarded from NextLink to NavigationLinkItem
  href?: string;
  onClick?: () => void;
}

export const NavigationLink = ({
  link,
  variant,
  isMenuOpen,
  onClick,
}: NavigationLinkProps) => {
  if (variant === 'restricted') {
    return (
      <NavigationLinkItem
        link={link}
        variant={variant}
        isMenuOpen={isMenuOpen}
        onClick={onClick}
      />
    );
  }
  if (variant === 'disabled')
    return (
      <NavigationLinkItem
        link={link}
        variant={variant}
        isMenuOpen={isMenuOpen}
      />
    );

  return (
    <NextLink href={link.path} legacyBehavior passHref>
      <NavigationLinkItem
        link={link}
        variant={variant}
        isMenuOpen={isMenuOpen}
        onClick={onClick}
      />
    </NextLink>
  );
};

const NavigationLinkItem = forwardRef<HTMLAnchorElement, NavigationLinkProps>(
  ({ link, isMenuOpen, variant, href, onClick }, ref) => {
    const { t } = useTranslation('navigationBar');

    const showTooltip = !isMenuOpen || variant === 'disabled';

    const {
      isNew = false,
      requireSubscription = false,
      isExperimental = false,
    } = link;

    return (
      <Link
        variant="inherit"
        $variant={variant}
        $isMenuOpen={isMenuOpen}
        data-testid="navigation-link"
        href={href}
        onClick={onClick}
        ref={ref}
      >
        <TooltipCustom
          title={variant === 'disabled' ? link.disabledTooltip : link.tooltip}
          placement="right"
          $hidden={!showTooltip}
        >
          <ListItemContainer key={link.text}>
            <ListItemIconCustom $isExperimental={isExperimental}>
              {link.icon}
              {!isMenuOpen && isNew && <NewFeatureDotIcon />}
            </ListItemIconCustom>
            <ListItemText
              primary={link.text}
              data-testid="navigation-link-name"
              primaryTypographyProps={{ variant: 'body2' }}
              secondaryTypographyProps={{ fontSize: 10 }}
              secondary={isExperimental ? '(experimental)' : undefined}
            />
            {isNew ? (
              <Fade in={isMenuOpen} appear timeout={{ enter: 1500, exit: 200 }}>
                <NewFeatureChip size="small" label={t('new')} color="success" />
              </Fade>
            ) : null}
            {requireSubscription ? (
              <PremiumCircle>
                <PremiumIcon fontSize="inherit" />
              </PremiumCircle>
            ) : null}
          </ListItemContainer>
        </TooltipCustom>
      </Link>
    );
  }
);
NavigationLinkItem.displayName = 'NavigationLinkItem';
