import MUITooltip, {
  tooltipClasses,
  TooltipProps,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Zoom } from '@mui/material';
import { colors, fontSize } from '@/theme';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip
    {...props}
    arrow
    TransitionComponent={Zoom}
    classes={{ popper: className }}
  >
    {props.children}
  </MUITooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.infinity,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.infinity,
    textAlign: 'center',
    padding: 8,
    fontSize: fontSize.xSmall,
    borderRadius: 8,
  },
  [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
    marginBottom: '8px',
  },
  [`&[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]: {
    marginRight: '8px',
  },
  [`&[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
    marginLeft: '8px',
  },
  [`&[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
    marginTop: '8px',
  },
}));
