import env from '@/utils/wrapped-env';

export interface AppConfig {
  apiHost: string;
  allowExperimentalFeatures: boolean;
  applicationVersion: string | undefined;
  auth0: {
    audience: string;
    clientId: string;
    domain: string;
    redirectUri: string;
  };
  billingBaseUrl: string;
  useSecureCookies: boolean;
  hotjarId: string | undefined;
  segmentWritingKey: string | undefined;
  sentryDsn: string;
  sentryEnvironment: string;
  subscriptionLinks: {
    basic: string;
    agency: string;
    business: string;
  };
  twicPicsDomain: string;
  uamBaseUrl: string;
  enableBillingCredits: boolean;
  skyReplacementUrl: string;
}

let appConfig: AppConfig | undefined;

export const getAppConfig = () => {
  if (appConfig === undefined) {
    appConfig = Object.freeze({
      apiHost: env('BACKEND_API_HOST') ?? 'missing_api_host',
      allowExperimentalFeatures: env('ALLOW_EXPERIMENTAL_FEATURES') === 'true',
      applicationVersion: env('APPLICATION_VERSION'),
      auth0: {
        audience: env('AUTH0_AUDIENCE') ?? 'missing_audience',
        clientId: env('AUTH0_CLIENT_ID') ?? 'missing_client_id',
        domain: env('AUTH0_DOMAIN') ?? 'missing_auth0_domain',
        redirectUri: env('AUTH0_REDIRECT_URI') ?? 'missing_redirect_uri',
      },
      billingBaseUrl: env('BILLING_BASE_URL') ?? 'missing_billing_url',
      useSecureCookies: env('ENABLE_SECURE_COOKIES') === 'true',
      hotjarId: env('HOTJAR_ID'),
      segmentWritingKey: env('SEGMENT_WRITING_KEY'),
      // Sentry DSN is the same on every env and should be defaulted at build time
      sentryDsn:
        env('SENTRY_DSN') ??
        'https://3ad3104670da4e1ab25d0c6e3186c39d@o56319.ingest.sentry.io/6047795',
      sentryEnvironment: env('SENTRY_ENVIRONMENT') ?? 'other',
      subscriptionLinks: {
        basic: env('BASIC_PLAN_URL') ?? 'missing_basic_url',
        agency: env('AGENCY_PLAN_URL') ?? 'missing_agency_url',
        business: env('BUSINESS_PLAN_URL') ?? 'missing_business_url',
      },
      twicPicsDomain: env('TWICPICS_DOMAIN') ?? 'https://cdn.meero.com',
      uamBaseUrl: env('UAM_BASE_URL') ?? 'missing_uam_base_url',
      enableBillingCredits: true,
      skyReplacementUrl:
        env('SKY_REPLACEMENT_URL') ?? 'missing_sky_replacement_url',
    });
  }

  return appConfig;
};
