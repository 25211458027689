import { useCallback, useEffect, useState } from 'react';

export const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState<boolean | null>(null);

  const updateTarget = useCallback(({ matches }: MediaQueryListEvent) => {
    setTargetReached(!matches);
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${width}px)`);
    media.addEventListener('change', updateTarget);

    setTargetReached(!media.matches);

    return () => media.removeEventListener('change', updateTarget);
  }, [width, updateTarget]);

  return targetReached;
};
