import { closeSnackbar, CustomContentProps } from 'notistack';
import React from 'react';
import { useTheme } from '@mui/material';
import { CloseIcon, IconButton, Typography } from '@/components/common';
import {
  AnnouncementSnackbarContent,
  BaseSnackbar,
  IconContainer,
  TextContainer,
} from './Snackbar.styles';

interface SnackbarProps extends CustomContentProps {
  title?: string;
  subtitle?: string;
  allowDismiss?: boolean;
}

export const CustomSnackbar = React.forwardRef<HTMLDivElement, SnackbarProps>(
  (
    {
      style,
      iconVariant,
      variant = 'default',
      title,
      message,
      id,
      persist,
      allowDismiss = false,
    },
    ref
  ) => {
    const { palette } = useTheme();

    const targetVariant = variant === 'announcement' ? 'default' : variant;

    const fontColor = palette[targetVariant].dark;
    const iconColor = palette[targetVariant].main;
    const backgroundColor = palette[targetVariant].light;
    const handleClose = () => {
      closeSnackbar(id);
    };

    return (
      <BaseSnackbar
        style={style}
        ref={ref}
        $backgroundColor={backgroundColor}
        $fontColor={fontColor}
      >
        <IconContainer $iconColor={iconColor}>
          {iconVariant[targetVariant]}
        </IconContainer>
        <TextContainer>
          {title !== undefined && (
            <Typography fontWeight={700}>{title}</Typography>
          )}
          <Typography variant="body1">{message}</Typography>
        </TextContainer>
        {allowDismiss || persist ? (
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : null}
      </BaseSnackbar>
    );
  }
);
CustomSnackbar.displayName = 'CustomSnackbar';

export const AnnouncementSnackbar = React.forwardRef<
  HTMLDivElement,
  SnackbarProps
>(({ style, title, subtitle, message, id, persist }, ref) => {
  const handleClose = () => {
    closeSnackbar(id);
  };

  return (
    <AnnouncementSnackbarContent style={style} ref={ref}>
      <Typography variant="h4" fontWeight={700}>
        {title}
      </Typography>
      {subtitle !== undefined && (
        <Typography
          variant="h4"
          fontWeight={400}
          fontStyle="italic"
          sx={{ marginTop: -1, marginBottom: 1 }}
        >
          {subtitle}
        </Typography>
      )}
      <Typography variant="body2" sx={{ textWrap: 'balance' }}>
        {message}
      </Typography>
      {persist && (
        <IconButton
          size="small"
          sx={{ position: 'absolute', right: 20, color: 'white' }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </AnnouncementSnackbarContent>
  );
});
AnnouncementSnackbar.displayName = 'AnnouncementSnackbar';
