import { TypographyProps } from '@mui/material/Typography';
import { Typography } from '@/components/common';

/**
 * A Typography component with the right default props to show an ellipsis when "overflowing"
 */
export function TypographyEllipsis({ children, ...rest }: TypographyProps) {
  const title = typeof children === 'string' ? children : undefined;

  return (
    <Typography
      component="span"
      overflow="hidden"
      maxWidth="100%"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      title={title}
      {...rest}
    >
      {children}
    </Typography>
  );
}
