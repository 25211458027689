import useSWR from 'swr';
import {
  CalendarDate,
  parseAbsoluteToLocal,
  toCalendarDate,
} from '@internationalized/date';
import { MSApiError } from '@/types/ApiError';
import { useAuth0Credentials } from '@/hooks/use-auth0-credentials';
import { WithIdType } from '@/types/ApiPlatform';
import { GetTokenAsync, msApiGet, SWRMSApiKey } from '../clients';

type StripeSubscriptionDTO = {
  price: number;
  currency: string;
  cancelAtPeriodEnd: boolean;
  hasBillingPortal: boolean;
};

export type BillingLicenceDTO = WithIdType<{
  uuid: string;
  status: 'active' | 'inactive' | 'past_due';
  type: 'subscribed' | 'free_trial';
  expirationDate: string;
  billingProduct: {
    uuid: string;
    billingPortal: boolean;
    trialDuration: number; // in days
    name: string | null;
    monthlyPropertyCredit: number;
    initialPropertyCreditBonus: number;
    maximumPropertyCredit: number;
  };
  stripeSubscription: StripeSubscriptionDTO | null;
}>;

export type LicenceStatus =
  | 'trialing'
  | 'trial-expired'
  | 'active'
  | 'active-but-cancelled'
  | 'inactive';

type WrappedLicence = BillingLicenceDTO & {
  computed: {
    status: LicenceStatus;
    expirationDate: CalendarDate;
    isPremium: boolean; // Premium means the user has a paid subscription (not a free trial)
  };
};

/**
 * GET /billing/licence
 * Retrieves the licence information of the current user
 * Computes necessary fields for the licence
 */
export function useLicence(shouldFetch: boolean = true) {
  const { userId, workspaceId, getTokenAsync } = useAuth0Credentials();

  const key: SWRMSApiKey = shouldFetch
    ? ['billing/licence', workspaceId, userId]
    : null;

  return useSWR<WrappedLicence, MSApiError, SWRMSApiKey>(
    key,
    ([_, wsId]) => fetchLicenceAndWrap(getTokenAsync, wsId),
    { revalidateOnFocus: true }
  );
}

async function fetchLicenceAndWrap(
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
): Promise<WrappedLicence> {
  const licence = await msApiGet<BillingLicenceDTO>(
    'billing/licence',
    getTokenAsync,
    workspaceId
  );

  const expirationDateTime = parseAbsoluteToLocal(licence.expirationDate);
  const expirationDate = toCalendarDate(expirationDateTime);

  return {
    ...licence,
    computed: {
      expirationDate,
      status: computeLicenceStatus(licence),
      isPremium: isPremium(licence),
    },
  };
}

function computeLicenceStatus(licence: BillingLicenceDTO): LicenceStatus {
  if (licence.type === 'free_trial') {
    switch (licence.status) {
      case 'active':
      case 'past_due':
        return 'trialing';
      case 'inactive':
        return 'trial-expired';
    }
  }

  switch (licence.status) {
    case 'active':
    case 'past_due':
      return licence.stripeSubscription?.cancelAtPeriodEnd === true
        ? 'active-but-cancelled'
        : 'active';
    case 'inactive':
      return 'inactive';
  }
}

export function isPremium(licence: BillingLicenceDTO) {
  return (
    licence.type === 'subscribed' &&
    (licence.status === 'active' || licence.status === 'past_due')
  );
}

type CustomerPortalDTO = {
  url: string;
};

/**
 * GET /billing/customer-portal
 * Retrieves the customer portal link for the current user
 */
export function getCustomerPortalUrl(
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) {
  return msApiGet<CustomerPortalDTO>(
    'billing/customer-portal',
    getTokenAsync,
    workspaceId
  );
}

type WorkspaceCreditsDTO = {
  nextCreditDate: string;
  propertyCredit: number;
  workspaceUuid: string;
};

/**
 * GET /workspace/{workspaceUuid}/credits
 * Retrieve the number of credits remaining on a workspace
 */
export const useWorkspaceCredits = (enableBillingCredits: boolean) => {
  const { workspaceId, getTokenAsync, userId } = useAuth0Credentials();

  return useSWR<WorkspaceCreditsDTO, MSApiError, SWRMSApiKey>(
    enableBillingCredits
      ? [`workspace/${workspaceId}/credits`, workspaceId, userId]
      : null,
    ([path, wsId]) => msApiGet(path, getTokenAsync, wsId)
  );
};
