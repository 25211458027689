// eslint-disable-next-line no-restricted-imports
import reactenv from '@beam-australia/react-env';

const env = (varName: string | undefined) => {
  if (varName === undefined) return undefined;

  // Force casting to (string | undefined) because react-env can actually return undefined (the lib typing is wrong)
  const value = reactenv(varName) as string | undefined;

  if (value === undefined || value.length === 0) {
    return undefined;
  }

  return value;
};

export default env;
