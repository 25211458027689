import { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { MenuOpenIcon } from '@/components/common';
import { isLinkActive } from '@/utils/navigationLink';
import { NavigationItem } from '@/types/Navigation/Navigation';

import { useUserWorkspaceContext } from '@/context/UserWorkspaceContext';
import { useNavigationItems } from '@/components/templates/Navigation/hooks/useNavigationItems';
import { trackButtonClicked } from '@/lib/analytics';
import { getSegmentPageLabel } from '@/lib/segment-label/page-label';
import { getNavigationBarEventLabel } from '@/lib/segment-label/event-label';
import { ProperShotLogo } from '@/components/icons/ProperShotLogo';
import { ProfileMenu } from '@/components/common/Menu/ProfileMenu/ProfileMenu';

import { LinkVariant, NavigationLink } from './NavigationLink/NavigationLink';
import {
  AppearingMenuIcon,
  Container,
  Drawer,
  DrawerBody,
  DrawerContainer,
  DrawerHeaderCustom,
  FadingMeeroRealtorsIcon,
  Group,
  GroupTitle,
  LogoContainer,
  NavigationItemsContainer,
  ToggleMenuIcon,
} from './NavigationBar.styles';
import { LicenceBanner } from '../LicenceBanner/LicenceBanner';
import { useNavigationRestriction } from '../hooks/useNavigationRestriction';

interface NavigationBarProps {
  hidden?: boolean;
  isOpen: boolean;
  onToggle: () => void;
}

export const NavigationBar = ({
  hidden = false,
  isOpen,
  onToggle,
}: NavigationBarProps) => {
  const navigationGroups = useNavigationItems();

  const ref = useRef<HTMLButtonElement | null>(null);

  const { experimentalFeatures } = useUserWorkspaceContext();

  const filteredNavigationGroups = useMemo(
    () =>
      navigationGroups.map((group) => ({
        ...group,
        links: experimentalFeatures
          ? group.links.filter((link) => link.isAllowed ?? true)
          : group.links.filter(
              (link) =>
                !(link.isExperimental ?? false) && (link.isAllowed ?? true)
            ),
      })),
    [experimentalFeatures, navigationGroups]
  );

  return (
    <Container $isHidden={hidden}>
      <Drawer variant="permanent" open={isOpen} data-open={isOpen}>
        <DrawerContainer $isMenuOpen={isOpen}>
          <DrawerHeaderCustom $isMenuOpen={isOpen}>
            <ToggleMenuIcon
              onClick={onToggle}
              ref={ref}
              data-testid="icon header"
            >
              {isOpen ? (
                <MenuOpenIcon fontSize="inherit" />
              ) : (
                <>
                  <AppearingMenuIcon fontSize="inherit" data-icon="menu" />
                  <FadingMeeroRealtorsIcon
                    color="primary"
                    data-icon="propershot"
                    height={20}
                  />
                </>
              )}
            </ToggleMenuIcon>
            <LogoContainer $isMenuOpen={isOpen}>
              <Link href="/">
                <ProperShotLogo height={24} />
              </Link>
            </LogoContainer>
          </DrawerHeaderCustom>
          <DrawerBody>
            <NavigationItemsContainer>
              {filteredNavigationGroups.map((list) => {
                if (list.links.length === 0) return null;

                return (
                  <Group key={list.id}>
                    {list.group && (
                      <GroupTitle variant="caption" $isOpened={isOpen} noWrap>
                        {list.title}
                      </GroupTitle>
                    )}
                    <ListMap links={list.links} isOpen={isOpen} />
                  </Group>
                );
              })}
            </NavigationItemsContainer>
          </DrawerBody>
          <LicenceBanner isOpen={isOpen} />
          <ProfileMenu isMenuOpen={isOpen} />
        </DrawerContainer>
      </Drawer>
    </Container>
  );
};

interface ListMapProps {
  links: NavigationItem[];
  isOpen: boolean;
}
const ListMap = ({ links, isOpen }: ListMapProps) => {
  const { pathname: currentPathname, asPath, query } = useRouter();
  const { handleOpenRestrictionDialog } = useNavigationRestriction();

  return (
    <>
      {links.map((link) => {
        let linkVariant: LinkVariant = 'default';

        if (link.isDisabled === true) {
          linkVariant = 'disabled';
        } else if (isLinkActive(link.path, currentPathname)) {
          linkVariant = 'active';
        } else if (link.requireSubscription ?? false) {
          linkVariant = 'restricted';
        }

        return (
          <NavigationLink
            key={link.id}
            link={link}
            variant={linkVariant}
            isMenuOpen={isOpen}
            onClick={() => {
              trackButtonClicked(getNavigationBarEventLabel(link), {
                page: getSegmentPageLabel(asPath, query),
              });
              if (link.requireSubscription === true) {
                handleOpenRestrictionDialog(link.id);
              }
            }}
          />
        );
      })}
    </>
  );
};
