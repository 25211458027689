import MUIButton from '@mui/material/Button';
import styled, { css } from '@/utils/styled';
import {
  colors,
  fontSize,
  fontWeight,
  getMeeSpacing,
  lineHeight,
} from '@/theme';

export const ButtonMeero = styled(MUIButton)<{
  $type?: 'primary' | 'secondary' | 'success' | 'warning' | 'dark' | 'editing';
  $size?: 'small' | 'medium' | 'large';
  $removeBorder?: boolean;
}>`
  border: ${({ $removeBorder }) => ($removeBorder === true ? 0 : '1px solid')};
  border-radius: 6px;
  text-transform: none;
  box-sizing: border-box;
  font-weight: ${fontWeight.semiBold};
  white-space: nowrap;
  min-width: fit-content;
  transition:
    background-color 500ms ease-in-out,
    color 500ms ease-in-out,
    border-color 500ms ease-in-out;

  &:hover {
    transition:
      background-color 100ms ease-in-out,
      color 100ms ease-in-out,
      border-color 500ms ease-in-out;
  }

  ${({ $type }) => {
    switch ($type) {
      case 'primary':
      default:
        return css`
          background-color: ${colors.primary};
          color: ${colors.white};
          border-color: ${colors.primary};

          &:hover {
            background-color: ${colors.primary};
          }

          &.Mui-disabled {
            background-color: ${colors.primary30};
            border-color: transparent;
            color: ${colors.white};
          }
        `;
      case 'dark':
        return css`
          background-color: transparent;
          color: ${colors.white};
          border-color: ${colors.miracle};

          &:hover {
            background-color: ${colors.white};
            color: rgba(0, 0, 0, 0.72);
            border-color: rgba(0, 0, 0, 0.72);
          }

          &.Mui-disabled {
            color: ${colors.miracle};
          }
        `;

      case 'secondary':
        return css`
          background-color: ${colors.white};
          color: ${colors.infinity};
          border-color: rgba(155, 155, 155, 0.3);
          transition: unset;

          &:hover {
            background-color: ${colors.whiteSmoke};
            color: ${colors.carbon};
          }

          &.Mui-disabled {
            background-color: ${colors.white};
            color: ${colors.hydrargyrum};
          }
        `;
      case 'editing':
        return css`
          background-color: ${colors.annaBanana};
          color: ${colors.infinity};
          transition: unset;

          &:hover {
            background-color: ${colors.annaBanana};
            color: ${colors.infinity};
          }
          &.Mui-disabled {
            opacity: 0.3;
          }
        `;
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      case 'small':
      default:
        return css`
          font-size: ${fontSize.small};
          line-height: ${lineHeight.xSmall};
          padding: ${getMeeSpacing(1, 1.5)};
        `;
      case 'medium':
        return css`
          font-size: ${fontSize.medium};
          line-height: 20px;
          padding: ${getMeeSpacing(1.5, 2)};
        `;
      case 'large':
        return css`
          font-size: ${fontSize.large};
          line-height: ${lineHeight.medium};
          padding: ${getMeeSpacing(1.5, 2.5)};
        `;
    }
  }};
`;
