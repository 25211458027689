import { default as LinkMUI } from '@mui/material/Link';
import { listItemClasses } from '@mui/material/ListItem';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { listItemTextClasses } from '@mui/material/ListItemText';
import { colors, fontSize, fontWeight, getMeeSpacing } from '@/theme';
import styled, { css } from '@/utils/styled';
import {
  MENU_SPEED,
  MENU_TIMING_FUNCTION,
} from '@/components/templates/Navigation/NavigationBar/NavigationBar.styles';
import { Chip, ListItem, ListItemIcon } from '@/components/common';
import { Tooltip } from '@/components/common/Tooltip/Tooltip';

export const Link = styled(LinkMUI)<{
  $isMenuOpen: boolean;
  $variant: 'default' | 'active' | 'disabled' | 'restricted';
}>`
  display: block;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;

  .${listItemClasses.root} {
    padding: ${getMeeSpacing(1)};
    transition:
      background-color 500ms ease-in-out,
      font-weight 300ms ease-in-out;

    ${({ $variant }) =>
      $variant !== 'disabled' &&
      css`
        &:hover {
          transition: background-color 100ms ease-in-out;
          svg {
            fill: ${colors.infinity};
          }
          .${listItemTextClasses.primary} {
            font-weight: ${fontWeight.semiBold};
          }
        }
      `}

    .${listItemTextClasses.primary}, .${listItemTextClasses.secondary} {
      opacity: ${({ $isMenuOpen }) => ($isMenuOpen ? '1' : '0')};
      white-space: nowrap;
      transition: opacity ${MENU_SPEED} ${MENU_TIMING_FUNCTION};
    }
  }

  .${listItemIconClasses.root} {
    transition: margin-left ${MENU_SPEED} ${MENU_TIMING_FUNCTION};
  }

  ${({ $variant }) => {
    switch ($variant) {
      case 'active':
        return css`
          background-color: ${colors.white};
          color: ${colors.infinity};
          outline: 1px solid ${colors.translucentWhite};
          filter: drop-shadow(0px 12px 24px rgba(18, 21, 24, 0.06));

          svg {
            fill: ${colors.infinity};
          }

          .${listItemTextClasses.primary} {
            font-weight: ${fontWeight.semiBold};
          }
        `;
      case 'disabled':
        return css`
          color: ${colors.translucentWhite};
          cursor: default;

          svg {
            fill: ${colors.translucentWhite};
          }
        `;
      default:
        return css`
          cursor: pointer;
          color: ${colors.infinity};
          svg {
            fill: ${colors.miracle};
          }

          .${listItemTextClasses.primary} {
            font-weight: ${fontWeight.normal};
          }
        `;
    }
  }};
`;

export const TooltipCustom = styled(Tooltip)<{ $hidden: boolean }>`
  opacity: ${({ $hidden }) => ($hidden ? '0' : '1')};
`;

export const ListItemContainer = styled(ListItem)`
  height: 40px;
  column-gap: ${getMeeSpacing(1)};
`;

export const ListItemIconCustom = styled(ListItemIcon)<{
  $isExperimental?: boolean;
}>`
  font-size: 20px; // drives the icon size
  min-width: 20px;
  position: relative;

  ${({ $isExperimental = false }) =>
    $isExperimental &&
    css`
      ::after {
        position: absolute;
        bottom: -10px;
        right: -7px;
        font-size: ${fontSize.xSmall};
        color: ${colors.dodgerollGold};
        content: '⚠';
      }
    `}
`;

// A little dot that pulses softly
const NewFeatureDotBase = styled('div')`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: pulse-animation 1500ms infinite;

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.4);
    }
    100% {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
    }
  }
`;

export const NewFeatureDotIcon = styled(NewFeatureDotBase)`
  position: absolute;
  right: -2px;
  top: -2px;
  background-color: ${colors.primary};
`;

export const NewFeatureChip = styled(Chip)`
  color: ${colors.white};
  height: 20px;
  animation: pulse-animation 2000ms infinite;

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
    }
  }
`;

export const PremiumCircle = styled('div')`
  display: flex;
  justify-content: center;
  background-color: ${colors.secondary};
  border-radius: 50%;
  padding: 3px;
  // drives the icon style
  color: ${colors.white};
  font-size: 14px;
`;
