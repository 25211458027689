import { UsersTabId } from '@/components/pages/Users/hooks/useUsersTabs';
import { MediaFormatted } from '@/types/Media/MediaFormatted';
import { NavigationItem } from '@/types/Navigation/Navigation';
import { SegmentPageLabel } from './page-label';

// Segment events tracking types
export type ContextData = Record<string, string | number | undefined | null>;

export type SegmentEventLabel =
  | 'Something Went Wrong Error'
  | 'Sign Up Now Clicked'
  | 'Log In Clicked'
  | 'Sign Up CTA Clicked'
  | 'Login Text Clicked'
  | 'Go To Login Clicked'
  | 'Start Now CTA Clicked'
  | 'No Connexion Error Displayed'
  | 'Connexion Button Clicked'
  | 'Confirm Password CTA'
  | 'Forgot Password Text Clicked'
  | 'Login CTA Clicked'
  | 'Continue With Google CTA Clicked'
  | 'Send Email CTA Clicked'
  | 'Forgot Return Log In CTA Clicked'
  | 'Reset Password CTA Clicked'
  | 'Change Return Log In CTA Clicked'
  | 'Home Menu Clicked'
  | 'Library Menu Clicked'
  | 'Overview Menu Clicked'
  | 'Users Menu Clicked'
  | 'Need Help Menu Clicked'
  | 'Activity Dropdown Clicked'
  | 'Home Empty State CTA Clicked'
  | 'Show Media Text Clicked'
  | 'Before Media Clicked'
  | 'After Media Clicked'
  | 'Media Clicked'
  | 'Check Photos Text Clicked'
  | 'Show All Media CTA Clicked'
  | 'Get Premium Access Now CTA Clicked'
  | 'Trial Period Over Contact Us CTA Clicked'
  | 'Profile Drop-down Clicked'
  | 'Profile Manage Profile Button Clicked'
  | 'Profile Other Workspaces CTA Clicked'
  | 'Select Workspace CTA Clicked'
  | 'Close Select Workspace CTA Clicked'
  | 'Profile Subscription CTA Clicked'
  | 'Profile Help Center Button Clicked'
  | 'Profile Language French Button Clicked'
  | 'Profile Language English Button Clicked'
  | 'Profile Logout Button Clicked'
  | 'Save Changed CTA Clicked'
  | 'Request Password Change CTA Clicked'
  | 'Cancel Change Password CTA Clicked'
  | 'Send Password Reset CTA Clicked'
  | 'Subscribe CTA Clicked'
  | 'Subscribe Plan CTA Clicked'
  | 'Contact Us CTA Clicked'
  | 'Manage Subscription CTA Clicked'
  | 'All Media Tab Clicked'
  | 'Albums Tab Clicked'
  | 'All Tab Clicked'
  | 'Processing Tab Clicked'
  | 'Rejected Tab Clicked'
  | 'Manual Check Tab Clicked'
  | 'Validated Tab Clicked'
  | 'Search Bar Clicked'
  | 'Quality Filter Clicked'
  | 'Date Filter Clicked'
  | 'Sort by Filter Clicked'
  | 'List Icon Clicked'
  | 'Grid Icon Clicked'
  | 'Album Clicked'
  | 'Download CTA Clicked'
  | 'Album Details Icon Clicked'
  | 'Album Details Back Arrow Clicked'
  | 'Change Media Arrow Clicked'
  | 'Zoom In Icon Clicked'
  | 'Single Download CTA Clicked'
  | 'Closed Icon Clicked'
  | 'Refresh Text Clicked'
  | 'Approve CTA Clicked'
  | 'Reject CTA Clicked'
  | 'Refusal Comment CTA Clicked'
  | 'Send Refusal Comment CTA Clicked'
  | 'View Valid Moderation Flags Text Clicked'
  | 'Enlarge Media Icon Clicked'
  | 'Slider Media Clicked'
  | 'Slider Arrow Icon Clicked'
  | 'Active Members Tab Clicked'
  | 'Pending Invitations Tab Clicked'
  | 'Disabled Members Tab Clicked'
  | 'Email Filter Clicked'
  | 'Invite Members CTA Clicked'
  | 'Content Manager CTA Clicked'
  | 'Content Manager Send Invitation CTA Clicked'
  | 'Pending Invitations Text Clicked'
  | 'Invitation Sent Back to Dashboard CTA Clicked'
  | 'Content Producer CTA Clicked'
  | 'Disable CTA Clicked'
  | 'Disable App Member CTA Clicked'
  | 'Resend Invitation CTA Clicked'
  | 'Enable CTA Clicked'
  | 'Enable App Member CTA Clicked'
  | 'Add A Content Producer CTA Clicked'
  | 'Content Producer Send Invitation CTA Clicked'
  | 'Cancel Content Producer Send Invitation CTA Clicked'
  | 'Download Model Text Clicked'
  | 'Upload CTA Clicked'
  | 'List Uploaded Message Displayed'
  | 'Send Invitations CTA Clicked'
  | 'Download Errors CTA Clicked'
  | 'Replace File CTA Clicked'
  | 'Language Dropdown Clicked'
  | 'Go To Web Platform Text Clicked'
  | 'Need Help Contact Us Text Clicked'
  | 'Write A Message Bar Clicked'
  | 'Access Application CTA Clicked'
  | 'Application Guide CTA Clicked'
  | 'Select file CTA Clicked'
  | 'Import CTA Clicked'
  | 'Invitations Go back CTA Clicked'
  | 'Invitations Confirm And Send CTA Clicked'
  | 'Cancel Content Manager Send Invitation CTA Clicked'
  | 'Back Arrow Icon Clicked'
  | 'Confirm Enable CTA Clicked'
  | 'Cancel Enable CTA Clicked'
  | 'Confirm Disable CTA Clicked'
  | 'Cancel Disable CTA Clicked'
  | 'Confirm Resend Invitation CTA Clicked'
  | 'Cancel Resend Invitation CTA Clicked'
  | 'Single invite CTA Clicked'
  | 'Mass Import CTA Clicked'
  | 'Photos And Albums CTA Clicked'
  | 'Free Trial Over Subscribe CTA Clicked'
  | 'Contact Us Text Clicked'
  | 'Privacy Policy Text Clicked'
  | 'Terms and Conditions Text Clicked'
  | 'Edit Album Name Picto Clicked'
  | 'Edit Album Name Save CTA Clicked'
  | 'Edit Album Name Cancel CTA Clicked'
  | 'Multiple Download CTA Clicked'
  | 'Magic Eraser CTA Clicked'
  | 'Eraser CTA'
  | 'Eraser Guidelines CTA'
  | 'Home Staging CTA'
  | 'Compare Property Clicked'
  | 'See Original CTA Clicked'
  | 'Save From Editor CTA Clicked'
  | 'Save As Copy CTA Clicked'
  | 'Download From Editor CTA Clicked'
  | 'Watermark Settings Menu Clicked'
  | 'Save Watermark Configuration CTA Clicked'
  | 'Album Download CTA Clicked'
  | 'Delete Media CTA Clicked'
  | 'Delete Album CTA Clicked'
  | 'Disable Watermark CTA Clicked'
  | 'Compliance Save CTA Clicked'
  | 'Upload From Desktop CTA Clicked'
  | 'Enhance My Photo CTA Clicked'
  | 'Add Photos Clicked'
  | 'Unknown event'
  | 'Magic Eraser Demo Clicked'
  | 'Home Staging Demo Clicked'
  | 'Sky Replacement Demo Clicked'
  | 'Pop-up Limitations Display'
  | 'Subscribe From Pop-up Clicked'
  | 'Sky Replacement CTA'
  | 'Wall Painting CTA'
  | QuickEditEventLabel;

export type QuickEditEventLabel =
  | 'Media Editor Crop Clicked'
  | 'Media Editor Finetune Clicked'
  | 'Media Editor Annotate Clicked'
  | 'Media Editor Frame Clicked';

type SortByContextDataLabel =
  | 'Email (A-Z)'
  | 'Email (Z-A)'
  | 'Reference (A-Z)'
  | 'Reference (Z-A)'
  | 'Oldest login date'
  | 'Most recent login date'
  | 'Oldest invitation'
  | 'Most recent invitation';

type SortContextDataLabelType =
  | 'emailAsc'
  | 'emailDesc'
  | 'oldestLogin'
  | 'mostRecentLogin'
  | 'oldestInvitation'
  | 'mostRecentInvitation'
  | null;

export type pageContextData =
  | 'Home'
  | 'Manage Profil'
  | 'Subscription'
  | 'Subscription_Billing'
  | 'Billing'
  | 'Overview'
  | 'Library_All Media'
  | 'Library_Albums'
  | 'Library_Albums Details'
  | 'Quality Analysis_All'
  | 'Quality Analysis_Processing'
  | 'Quality Analysis_Rejected'
  | 'Quality Analysis_Manual Check'
  | 'Quality Analysis_Validated'
  | 'Enhancement_All Media'
  | 'Enhancement_Albums'
  | 'Enhancement_Album Details'
  | 'Users_Members'
  | 'Users_Pending Invitations'
  | 'Users_Disable Members';

export const getSortLabelValue = (
  sortLabel: SortByContextDataLabel | null
): ContextData => {
  return { ordered_by: sortLabel };
};

export const getSortByContextDataLabel = (
  sortValue: SortContextDataLabelType | undefined
): SortByContextDataLabel | null => {
  switch (sortValue) {
    case 'emailAsc':
      return 'Email (A-Z)';
    case 'emailDesc':
      return 'Email (Z-A)';
    case 'oldestLogin':
      return 'Oldest login date';
    case 'mostRecentLogin':
      return 'Most recent login date';
    case 'oldestInvitation':
      return 'Oldest invitation';
    case 'mostRecentInvitation':
      return 'Most recent invitation';
    default:
      return null;
  }
};

export const getTabSegmentEventLabel = (id: UsersTabId): SegmentEventLabel => {
  switch (id) {
    case 'members':
      return 'Active Members Tab Clicked';
    case 'pending-invitations':
      return 'Pending Invitations Tab Clicked';
    case 'disabled-members':
      return 'Disabled Members Tab Clicked';
    default:
      return 'Active Members Tab Clicked';
  }
};

export const getMediaContextData = (
  pageLabel: SegmentPageLabel | undefined,
  media: MediaFormatted | undefined
): ContextData => {
  return {
    page: pageLabel,
    album_uuid: media?.albumUuid,
    media_uuid: media?.uuid,
    upload_date: media?.uploadDate,
  };
};

export const getWatermarkConfigContextData = (
  page: SegmentPageLabel,
  watermark_selection: 'optional' | 'mandatory',
  logo_position: string | undefined,
  size_slider_value: number,
  opacity_slider_value: number,
  uploaded_logo: number
): ContextData => {
  return {
    page: page,
    watermark_selection: watermark_selection,
    logo_position: logo_position,
    size_slider_value: size_slider_value,
    opacity_slider_value: opacity_slider_value,
    uploaded_logo: uploaded_logo,
  };
};

export const getNavigationBarEventLabel = (
  navigationItem: NavigationItem
): SegmentEventLabel => {
  switch (navigationItem.id) {
    case 'home': {
      return 'Home Menu Clicked';
    }
    case 'library': {
      return 'Library Menu Clicked';
    }
    case 'users': {
      return 'Users Menu Clicked';
    }
    case 'watermark': {
      return 'Watermark Settings Menu Clicked';
    }
    case 'overview': {
      return 'Overview Menu Clicked';
    }
    case 'help': {
      return 'Need Help Menu Clicked';
    }
    default: {
      return 'Unknown event';
    }
  }
};
