import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { AppMemberInvitationValidationResult } from '@/types/AppMemberInvitation/AppMemberInvitationValidationResult';

interface UsersImportState {
  invitationsFile: File | null;
  validationData: AppMemberInvitationValidationResult | null;
  handleResetFile: () => void;
  setInvitationsFile: Dispatch<SetStateAction<File | null>>;
  setValidationData: Dispatch<
    SetStateAction<AppMemberInvitationValidationResult | null>
  >;
}

const UsersImportContext = createContext<UsersImportState>({
  invitationsFile: null,
  validationData: null,
  handleResetFile: () => null,
  setInvitationsFile: () => null,
  setValidationData: () => null,
});

export type UsersImportProviderProps = {
  children?: ReactNode;
};

export const UsersImportProvider = ({ children }: UsersImportProviderProps) => {
  const [invitationsFile, setInvitationsFile] = useState<File | null>(null);

  const [validationData, setValidationData] =
    useState<AppMemberInvitationValidationResult | null>(null);

  const handleResetFile = () => {
    setValidationData(null);
    setInvitationsFile(null);
  };

  return (
    <UsersImportContext.Provider
      value={{
        invitationsFile,
        validationData,
        handleResetFile,
        setInvitationsFile,
        setValidationData,
      }}
    >
      {children}
    </UsersImportContext.Provider>
  );
};

export const useUsersImport = () => {
  return useContext(UsersImportContext);
};
