import { useEffect, useState } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useLocalStorage } from '@/hooks/localStorage/useLocalStorage';
import { FORCE_SMALL_NAVBAR_BELOW } from '@/components/templates/Navigation/NavigationBar/NavigationBar.styles';

export const useNavigationBarState = () => {
  const [isOpenLocalStorage, setIsOpenLocalStorage] = useLocalStorage(
    'ms_navbar_open',
    true
  );
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);

  // Necessary to avoid hydration errors, open state will always return false on the server
  useEffect(() => {
    setIsNavBarOpen(isOpenLocalStorage);
  }, [isOpenLocalStorage]);

  const isBelowBreakpointLaptop = useMediaQuery(FORCE_SMALL_NAVBAR_BELOW);

  useEffect(() => {
    if (isBelowBreakpointLaptop === true) {
      setIsNavBarOpen(false);
    }
  }, [isBelowBreakpointLaptop]);

  return {
    isNavBarOpen,
    toggleNavBar: () => setIsOpenLocalStorage((prev) => !prev),
  };
};
