import { useAuth0 } from '@auth0/auth0-react';
import { Auth0User } from '@/api/auth0/user';
import { useUserWorkspaceContext } from '@/context/UserWorkspaceContext';

export function useAuth0Credentials() {
  const { user, getAccessTokenSilently } = useAuth0<Auth0User>();
  const { currentWorkspaceId } = useUserWorkspaceContext();

  return {
    userId: user?.['https://www.meero.com/current_user'] ?? null,
    workspaceId: currentWorkspaceId,
    getTokenAsync: getAccessTokenSilently,
  };
}
