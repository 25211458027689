export type DateTimeFormatType =
  | 'year-month-day-short' // e.g. Feb 27, 2024
  | 'year-month-day-long' // e.g. February 27, 2024
  | 'month-day-two-digit' // e.g. 02/27
  | 'month-day-short' // e.g. Feb 27
  | 'month-day-hour-short'; // e.g. Feb 27, 5:12 PM

const dateTimeFormatterCache = new Map<string, Intl.DateTimeFormat>();

/**
 * Create an Intl.DateTimeFormat formatter function
 * @param type the type of date time formatter to create
 */
export function createDateFormatter(
  type: DateTimeFormatType,
  locale: string = 'en-US'
): Intl.DateTimeFormat {
  const cacheKey = `${type}-${locale}`;
  const cached = dateTimeFormatterCache.get(cacheKey);
  if (cached) return cached;

  const { DateTimeFormat } = Intl;
  let formatter: Intl.DateTimeFormat;

  switch (type) {
    case 'year-month-day-long':
      formatter = new DateTimeFormat(locale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
      break;

    case 'year-month-day-short':
      formatter = new DateTimeFormat(locale, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
      break;
    case 'month-day-two-digit':
      formatter = new DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
      });
      break;
    case 'month-day-short':
      formatter = new DateTimeFormat(locale, {
        day: 'numeric',
        month: 'short',
      });
      break;
    case 'month-day-hour-short':
      formatter = new DateTimeFormat(locale, {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
      });
      break;
  }

  dateTimeFormatterCache.set(cacheKey, formatter);

  return formatter;
}
