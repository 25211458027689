import useSWRImmutable from 'swr/immutable';
import { MSApiError } from '@/types/ApiError';
import { useAuth0Credentials } from '@/hooks/use-auth0-credentials';
import { GetTokenAsync, msApiGet, msApiPost, SWRMSApiKey } from '../clients';

export type WorkspaceConfig = {
  isWatermarkActive: boolean;
  isWatermarkConfigured: boolean;
  isWatermarkMandatory: boolean;
};
/**
 * GET /customer/workspace/{uuid}
 * Get workspace information
 */
interface WorskpaceInfoDTO {
  uuid: string;
  name: string;
  vertical: string;
  default_workflow_uuid: string;
  dashboard_url: string;
  workspace_config: WorkspaceConfig;
}

export const useWorskpaceInfo = () => {
  const { userId, workspaceId, getTokenAsync } = useAuth0Credentials();

  const apiUrl = `customer/workspace/${workspaceId ?? 'missing_w_id'}`;

  return useSWRImmutable<WorskpaceInfoDTO, MSApiError, SWRMSApiKey>(
    [apiUrl, workspaceId, userId],
    ([path, wsId]) => msApiGet(path, getTokenAsync, wsId)
  );
};

export type HorizontalPosition = 'left' | 'center' | 'right';
export type VerticalPosition = 'top' | 'middle' | 'bottom';
type StringBoolean = 'true' | 'false';

export interface WatermarkConfigDTO {
  isActive: StringBoolean;
  isMandatory: StringBoolean;
  uuid: string;
  overlay: string;
  horizontalPosition: HorizontalPosition;
  verticalPosition: VerticalPosition;
  sizeRatio: number;
  opacity: number;
}

export function useWatermarkConfig() {
  const { userId, workspaceId, getTokenAsync } = useAuth0Credentials();

  const apiUrl = `customer/workspace/${workspaceId}/watermark-config`;

  return useSWRImmutable<WatermarkConfigDTO, MSApiError, SWRMSApiKey>(
    [apiUrl, workspaceId, userId],
    ([path, wsId]) => msApiGet(path, getTokenAsync, wsId)
  );
}

/**
 * POST /customer/workspace/{uuid}/watermark-config
 * Create workspace watermark configuration
 */
export interface CreateWatermarkConfigDTO {
  isActive: StringBoolean;
  isMandatory: StringBoolean;
  file: File | null;
  horizontalPosition: HorizontalPosition;
  verticalPosition: VerticalPosition;
  sizeRatio: number;
  opacity: number;
}
export function createWatermarkConfig(
  workspaceId: string | null,
  getTokenAsync: GetTokenAsync,
  body: CreateWatermarkConfigDTO
) {
  const formData = new FormData();
  formData.append('isActive', body.isActive);
  if (body.file !== null) {
    formData.append('file', body.file);
  }
  formData.append('horizontalPosition', body.horizontalPosition);
  formData.append('verticalPosition', body.verticalPosition);
  formData.append('sizeRatio', String(body.sizeRatio));
  formData.append('opacity', String(body.opacity));
  formData.append('isMandatory', body.isMandatory);

  return msApiPost<WatermarkConfigDTO>(
    `customer/workspace/${workspaceId}/watermark-config`,
    getTokenAsync,
    workspaceId,
    {
      body: formData,
    }
  );
}
