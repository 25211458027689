import Script from 'next/script';
import * as snippet from '@segment/snippet';
import { useEffect } from 'react';
import { getAppConfig } from '@/config/env';

/**
 * WARNING: This component injects a script tag into the DOM.
 * Modifying the content of the script might alter the current CSP headers.
 * Make sure the script-src directives are up to date (next-headers.config.js)
 */
function renderSnippet(apiKey: string) {
  const opts: snippet.Options = {
    apiKey,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: false,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

export const SegmentAnalytics = () => {
  const { segmentWritingKey } = getAppConfig();

  useEffect(() => {
    if (segmentWritingKey === undefined) {
      console.info('Segment is disabled');
    }
  }, [segmentWritingKey]);

  if (segmentWritingKey === undefined) {
    return null;
  }

  return (
    <Script
      id="segment-script"
      dangerouslySetInnerHTML={{ __html: renderSnippet(segmentWritingKey) }}
    />
  );
};
