import { createContext, ReactNode, useContext, useState } from 'react';
import { Dialog, DialogConfig } from '@/components/common/Dialog/Dialog';

type DialogContextState = {
  openDialog: (config: DialogConfig) => void;
  dismissDialog: () => void;
};

const DialogContext = createContext<DialogContextState>({
  openDialog: () => null,
  dismissDialog: () => null,
});

export type DialogProviderProps = {
  children?: ReactNode;
};

type DialogState = {
  isOpen: boolean;
} & DialogConfig;

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
    title: '',
    onConfirm: () => {},
    onDismiss: () => {},
  });

  const openDialog = (config: DialogConfig) => {
    setDialogState({
      isOpen: true,
      ...config,
    });
  };

  const dismissDialog = () => {
    setDialogState((currState) => ({
      ...currState,
      isOpen: false,
    }));
  };

  return (
    <DialogContext.Provider
      value={{
        openDialog,
        dismissDialog,
      }}
    >
      <Dialog {...dialogState} />
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  return useContext(DialogContext);
};
