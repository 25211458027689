import { SnackbarContent } from 'notistack';
import styled from '@/utils/styled';
import { colors, getMeeSpacing } from '@/theme';
import { getTwicPicsUrl } from '@/lib/twicpics';

export const BaseSnackbar = styled(SnackbarContent)<{
  $backgroundColor: string;
  $fontColor: string;
}>`
  min-height: 56px;
  display: flex;
  padding: ${getMeeSpacing(1.5)};
  column-gap: ${getMeeSpacing(1.5)};
  border-radius: 12px;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $fontColor }) => $fontColor};
`;
const backgroundUrl = getTwicPicsUrl('webapp/quick_edits_illu.png?v=3', [
  'resize=600',
  'quality=100',
]);

export const AnnouncementSnackbarContent = styled(SnackbarContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: ${getMeeSpacing(1)};
  padding: ${getMeeSpacing(3)};
  width: 360px;
  height: 260px;
  border-radius: 12px;
  background-color: ${colors.infinity};
  background-image: url(${backgroundUrl});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 160px;
  color: ${colors.white};

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const IconContainer = styled('div')<{
  $iconColor: string;
}>`
  color: ${({ $iconColor }) => $iconColor};
`;

export const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;
