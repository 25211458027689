import useSWRImmutable from 'swr/immutable';
import { useAuth0 } from '@auth0/auth0-react';
import { MSApiError } from '@/types/ApiError';
import { GetTokenAsync, msApi, msApiGet, msApiPost } from '../clients';
import { MeeroSuiteRole } from './users-invitations';
import { Auth0User } from '../auth0/user';
import { UserLocale } from './app-members';

export type Vertical = 'food' | 'real_estate';

export type UserDTO = {
  identifier: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  locale: UserLocale;
  reference: string;
  roles: Array<MeeroSuiteRole>;
  applicationUuid: string;
  albumCount: number;
  accountUuid: string;
  defaultWorkflowUuid: string;
  lastLoginAt: string;
  createdAt: string;
  meeroAccount: boolean;
  vertical: {
    id: number;
    vertical: Vertical;
  };
  userInvitation: UserInvitationDTO | null;
};

export type UserInvitationDTO = {
  id: number;
  accountUuid: string;
  accountName: string;
  invitedByEmail: string;
  invitedByFirstname: string;
  invitedByLastname: string;
  token: string;
};

/**
 * GET /customer/user/{identifier}
 * Retrieves the user info in Meero Suite back-end based on the auth0 user
 */
export function useCurrentMsUser() {
  const { user, getAccessTokenSilently } = useAuth0<Auth0User>();

  const identifier = user?.['https://www.meero.com/actor_tracking'];

  const cacheKey =
    identifier === undefined ? null : [`customer/user/${identifier}`];

  const {
    data: msUser,
    isLoading,
    error,
    mutate,
  } = useSWRImmutable<UserDTO, MSApiError, string[] | null>(
    cacheKey,
    ([path]) => msApiGet(path, getAccessTokenSilently)
  );

  const isContentManager =
    msUser?.roles.includes('ms-content-manager') ?? false;

  return {
    msUser,
    isContentManager,
    mutateUser: mutate,
    isLoading,
    isError: Boolean(error),
  };
}

export type PatchUserDTO = {
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  locale?: string;
  countryCode?: string;
};

/**
 * PATCH /customer/user/{identifier}
 * Update user info
 */
export async function updateUserInfo(
  body: PatchUserDTO,
  identifier: string,
  getTokenAsync: GetTokenAsync
) {
  const token = await getTokenAsync();

  return msApi.patch(`customer/user/${identifier}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/merge-patch+json',
    },
    json: body,
  });
}

/**
 * POST /customer/user/{identifier}/enable
 * Enable a disabled user
 */
export const enableUserAccount = (
  identifier: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  return msApiPost(
    `customer/user/${identifier}/enable`,
    getTokenAsync,
    workspaceId
  );
};

/**
 * POST /customer/user/{identifier}/disable
 * Disable a user
 */
export const disableUserAccount = (
  identifier: number | string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  return msApiPost(
    `customer/user/${identifier}/disable`,
    getTokenAsync,
    workspaceId
  );
};
