import { NextRouter } from 'next/router';
import { z } from 'zod';
import { EditorTabId } from '@/components/pages/Editor/hooks/useEditorsTab';

// Segment utils function to check the url path and return the right segment label
export function getSegmentPageLabel(
  path: string,
  query: NextRouter['query']
): SegmentPageLabel | undefined {
  const id = z.coerce.string().optional().parse(query.id);
  const urlWithoutQuery = path.split('?')[0];
  const editorMode = z.coerce.string().optional().parse(query.tool) as
    | EditorTabId
    | undefined;

  switch (urlWithoutQuery) {
    case '/':
      return 'Home';
    // Welcome label
    case '/welcome':
      return 'Welcome';

    // Signup label
    case '/signup':
      return 'Signup';

    case '/profile':
      return 'Manage Profile';

    case '/login':
      return 'Welcome';

    // Library labels
    case '/library':
      return 'Library_Albums';

    case '/library/media':
      return 'Library_All Media';

    case id !== undefined && `/library/albums/${id}`:
      return 'Library_Albums Details';

    case '/editor':
      switch (editorMode) {
        case undefined:
        case 'browse-property':
          return 'Media Editor Browse Property';

        case 'magic-eraser':
          return 'Media Editor Eraser';

        case 'home-staging':
          return 'Media Editor Home Staging';

        case 'sky-replacement':
          return 'Media Editor Sky Replacement';

        case 'wall-painting':
          return 'Media Editor Wall Painting';

        case 'crop':
          return 'Media Editor Crop';

        case 'finetune':
          return 'Media Editor Finetune';

        case 'annotate':
          return 'Media Editor Annotate';

        case 'frame':
          return 'Media Editor Frame';
      }
      break;

    // Overview label
    case '/app-overview':
      return 'Overview';

    // Users labels
    case '/users':
      return 'Users_Members';

    case '/users/pending-invitations':
      return 'Users_Pending Invitations';

    case '/users/disabled-members':
      return 'Users_Disable Members';

    //Billing labels
    case '/subscription':
      return 'Subscription';

    case '/subscription/billing-profile':
      return 'Subscription_Compliance';

    //Watermark labels
    case '/watermark':
      return 'Watermark Settings';

    // Email verification
    case '/mobile-store-redirect':
      return 'Email Verification';

    default:
      return undefined;
  }
}

// Segment pages tracking types
type Welcome = 'Welcome';
type Signup = 'Signup';
type SignupInvitationConfirmed = 'Signup Invitation Confirmed';
type EmailVerification = 'Email Verification';
type SelfOnboarding = 'Self Onboarding';
type Login =
  | 'Forgot Password Text Clicked'
  | 'Login CTA Clicked'
  | 'Continue With Google CTA Clicked';
type Home = 'Home' | 'Manage Profile';
type Library =
  | 'Library_All Media'
  | 'Library_Albums'
  | 'Library_Albums Details';
type MediaViewer = 'Media Viewer';
type Editor =
  | 'Media Editor Browse Property'
  | 'Media Editor Eraser'
  | 'Media Editor Home Staging'
  | 'Media Editor Sky Replacement'
  | 'Media Editor Wall Painting'
  | 'Media Editor Crop'
  | 'Media Editor Finetune'
  | 'Media Editor Annotate'
  | 'Media Editor Frame';
type QualityAnalysis =
  | 'Quality Analysis_All'
  | 'Quality Analysis_Processing'
  | 'Quality Analysis_Rejected'
  | 'Quality Analysis_Manual Check'
  | 'Quality Analysis_Validated';
type Overview = 'Overview';
type Users =
  | 'Users_Members'
  | 'Users_Pending Invitations'
  | 'Users_Disable Members'
  | 'Users_Invite Content Producer'
  | 'Users_Upload Complete';
type Billing = 'Subscription' | 'Billing' | 'Subscription_Compliance';
type Watermark = 'Watermark Settings';

export type SegmentPageLabel =
  | Welcome
  | Signup
  | SignupInvitationConfirmed
  | EmailVerification
  | SelfOnboarding
  | Login
  | Home
  | Library
  | QualityAnalysis
  | Overview
  | Users
  | Billing
  | Editor
  | MediaViewer
  | Watermark;

export type UrlPath =
  | '/welcome'
  | '/signup'
  | '/signup/verification'
  | '/profile'
  | '/subscription'
  | '/subscription/billing-profile'
  | '/app-overview'
  | '/'
  | '/library'
  | `/library/albums/${string}`
  | '/library/media'
  | '/quality-analysis'
  | '/quality-analysis/processing'
  | '/quality-analysis/rejected'
  | '/quality-analysis/manual-check'
  | '/quality-analysis/validated'
  | '/users'
  | '/users/pending-invitations'
  | '/users/disabled-members'
  | '/editor'
  | '/watermark'
  | '/mobile-store-redirect';
