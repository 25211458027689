import {
  getLocalTimeZone,
  parseAbsoluteToLocal,
  today,
} from '@internationalized/date';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { createDateFormatter } from '@/utils/date-formatter';
import {
  LicenceStatus,
  useLicence,
  useWorkspaceCredits,
} from '@/api/meero-suite/billing';
import { getAppConfig } from '@/config/env';

type LicenceBanner = {
  status: LicenceStatus;
  statusLabel: string;
  message: string;
  showCta: boolean;
  ctaLabel: string;
};

const selectCtaLabelKey = (
  licenceStatus: LicenceStatus,
  propertyCredit: number | undefined
): string => {
  if (licenceStatus === 'active' && propertyCredit === 0)
    return 'licenceBanner.cta.upgradeSubscription';

  if (licenceStatus === 'active-but-cancelled')
    return 'licenceBanner.cta.renewSubscription';

  return 'licenceBanner.cta.subscribe';
};

export function useLicenceBanner(): LicenceBanner | undefined {
  const { t } = useTranslation('navigationBar');
  const { locale } = useRouter();
  const { data: licence } = useLicence();
  const { enableBillingCredits } = getAppConfig();
  const { data: propertyCredit } = useWorkspaceCredits(enableBillingCredits);

  if (
    licence === undefined ||
    licence.billingProduct.monthlyPropertyCredit >= 900 // case of custom plan with unlimited properties
  ) {
    return undefined;
  }

  const { billingProduct, computed } = licence;

  const expirationDateTime = parseAbsoluteToLocal(licence.expirationDate);
  const formatter = createDateFormatter('month-day-two-digit', locale);
  const nowDate = today(getLocalTimeZone());

  return {
    status: computed.status,
    statusLabel: t(`licenceBanner.status.${computed.status}`, {
      planName: licence.billingProduct.name ?? 'N/A',
    }),
    message: t(`licenceBanner.message.${computed.status}`, {
      daysLeft: computed.expirationDate.compare(nowDate),
      newProperties: billingProduct.monthlyPropertyCredit,
      renewalDate: formatter.format(expirationDateTime.toDate()),
      endDate: formatter.format(expirationDateTime.toDate()),
    }),
    showCta:
      !computed.isPremium ||
      propertyCredit?.propertyCredit === 0 ||
      computed.status === 'active-but-cancelled',
    ctaLabel: t(
      selectCtaLabelKey(computed.status, propertyCredit?.propertyCredit)
    ),
  };
}
