import useSWRImmutable from 'swr/immutable';

import { useAuth0Credentials } from '@/hooks/use-auth0-credentials';
import { GetTokenAsync, uamApi } from '../clients';
import { UAMApiError, WorkspaceSchema, WorkspacesListDTO } from './types';

async function getUserWorkspaces(path: string, getTokenAsync: GetTokenAsync) {
  const token = await getTokenAsync();

  return uamApi
    .get(path, {
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .json<WorkspacesListDTO>()
    .then((rawResult) =>
      rawResult.map((rawWorkspace) => WorkspaceSchema.parse(rawWorkspace))
    )
    .catch((err) => {
      console.error(err);
      throw new Error('Failed to fetch user workspaces');
    });
}

export function useUamWorkspaces() {
  const { userId, workspaceId, getTokenAsync } = useAuth0Credentials();

  const cacheKey = userId !== null ? `user/${userId}/workspace` : null;

  const {
    data: workspaces,
    isLoading,
    error,
  } = useSWRImmutable<WorkspacesListDTO, UAMApiError, string | null>(
    cacheKey,
    (path) => getUserWorkspaces(path, getTokenAsync)
  );

  return {
    currentWorkspace: workspaces?.find((ws) => ws.uuid === workspaceId),
    workspaces,
    isLoading,
    isError: Boolean(error),
  };
}
