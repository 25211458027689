import { getAppConfig } from '@/config/env';

/**
 * Returns a TwicPics formatted URL given a s3 key and an optional set of transformations
 * List of possible transformations can be found here : https://www.twicpics.com/docs/reference/transformations
 */
export function getTwicPicsUrl(s3Key: string, transform?: Array<string>) {
  const { twicPicsDomain } = getAppConfig();

  const actualTransform =
    transform?.reduce((prev, next) => `${prev}/${next}`, '') ?? '';

  const separator = s3Key.includes('?') ? '&' : '?';

  return `${twicPicsDomain}/${s3Key.concat(separator)}twic=v1${actualTransform}`;
}
