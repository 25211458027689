export const assets = {
  /**
   * Files assets stored on our S3 bucket
   */
  files: {
    csvInvitationTemplate:
      'https://assets.propershot.com/webapp/content_producers_import_template.csv',
    termsOfService:
      'https://assets.propershot.com/webapp/legal/propershot-terms-of-service-fr-en.pdf',
    privacyPolicy:
      'https://assets.propershot.com/webapp/legal/propershot-privacy-policy-fr-en.pdf',
  },
  /**
   * Image assets stored on our S3 bucket that must be loaded through our CDN (TwicPics)
   * Path must start with webapp/
   */
  cdnImageAssets: {
    announcementCover: 'webapp/announcement-cover.png',
    emailSent: 'webapp/email_sent.svg',
    fallbackMedia: 'webapp/fallback_media_large.jpg',
    properShotPhone: 'webapp/onboarding/propershot_phone.png',
    androidBadge: 'webapp/badges/google-play-badge.svg',
    iosBadge: 'webapp/badges/app-store-badge.svg?v=new',
    subscriptionCover: 'webapp/subscription_cover_2.png',
    greySkyPreview: 'webapp/skies/previews/house-grey-sky original.jpg',
    slideshow: {
      slide1: {
        before: 'webapp/slideshow/slide_1_before.jpeg',
        after: 'webapp/slideshow/slide_1_after.jpeg',
        alt: 'meero suite realors sample',
      },
      slide2: {
        before: 'webapp/slideshow/slide_2_before.jpeg',
        after: 'webapp/slideshow/slide_2_after.jpeg',
        alt: 'meero suite realors sample',
      },
      slide3: {
        before: 'webapp/slideshow/slide_3_before.jpeg',
        after: 'webapp/slideshow/slide_3_after.jpeg',
        alt: 'meero suite realors sample',
      },
    },
    watermark: {
      watermarkSample1: 'webapp/watermark/sample-image-1.jpg?v=2',
      watermarkSample2: 'webapp/watermark/sample-image-2.jpg?v=2',
      watermarkSample3: 'webapp/watermark/sample-image-3.jpg?v=2',
      watermarkSample4: 'webapp/watermark/sample-image-4.jpg?v=2',
    },
    homeStaging: {
      american: 'webapp/home-staging/v2-american.jpeg',
      bali: 'webapp/home-staging/v2-bali.jpeg',
      bohemian: 'webapp/home-staging/v2-bohemian.jpeg',
      'english countryside': 'webapp/home-staging/v2-countryside.jpeg',
      ikea: 'webapp/home-staging/v2-ikea.jpeg',
      industrial: 'webapp/home-staging/v2-industrial.jpeg',
      luxury: 'webapp/home-staging/v2-luxury.jpeg',
      modern: 'webapp/home-staging/v2-modern.jpeg',
      scandinavian: 'webapp/home-staging/v2-scandinavian.jpeg',
      seaside: 'webapp/home-staging/v2-seaside.jpeg',
      traditional: 'webapp/home-staging/v2-traditional.jpeg',
      zen: 'webapp/home-staging/v2-zen.jpeg',
    },
    onboardingModal: {
      step1: 'webapp/onboarding/onboarding-modal-1.jpeg',
      step2: 'webapp/onboarding/onboarding-modal-2.jpeg',
      step3: 'webapp/onboarding/onboarding-modal-3.jpeg',
    },
    restrictionDialogHeaderImage: 'webapp/restriction_dialog_header.png',
    homeEmptyState: 'webapp/home_empty_state.svg',
  },
  /**
   * Video assets stored on our S3 bucket that must be loaded through our CDN (TwicPics)
   * Path must start with videos/
   */
  cdnVideoAssets: {
    magicEraserDemo: 'videos/magic-eraser-demo.mp4',
    magicEraserStep1: 'videos/magic-eraser-step-1.mp4',
    magicEraserStep2: 'videos/magic-eraser-step-2.mp4',
    magicEraserStep3: 'videos/magic-eraser-step-3.mp4',
  },
} as const;

export const externalLinks = {
  careContactForm: 'https://www.propershot.com/contact/contact-support',
  helpCenterUrl: 'https://help.propershot.com',
  mobileDeepLinksUrl: 'meero-realtors://login',
  properShotAppStoreUrl:
    'https://apps.apple.com/fr/app/propershot/id1608365779',
  propershotPlayStoreUrl:
    'https://play.google.com/store/apps/details?id=com.meero.realtors&hl=fr',
  salesAppointmentUrl: 'https://meetings-eu1.hubspot.com/antoine-martin',
  magicEraseDemoUrl: 'https://www.youtube.com/watch?v=2uQmy4nBTwg',
  homeStagingDemoUrl: 'https://www.youtube.com/watch?v=YWsjhkKz64g',
  skyReplacementDemoUrl: 'https://www.youtube.com/watch?v=qHjxBJwgwgA',
};
