import { Box, Modal } from '@/components/common';
import { breakpoints, fontSize } from '@/theme';
import styled from '@/utils/styled';

export const StyledModal = styled(Modal)`
  backdrop-filter: blur(6px);
  background-color: rgba(35, 38, 49, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  user-select: none;
  .MuiTypography-h5 {
    font-size: ${fontSize.medium};
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    .MuiTypography-h5 {
      font-size: ${fontSize.xLarge};
    }
  }
`;

export const StyledBox = styled(Box)`
  outline: 0;
`;
