import { makeMap } from '@/utils/helpers';

// A conditional type to list all features that can be enabled/disabled through feature toggling
export type Feature = 'dashboard' | 'create-property';
export type FeatureStatus = {
  enabled: Array<Feature>;
  disabled: Array<Feature>;
};

// For each workspace in key, an array of enabled features in value
const workspaceFeatures = makeMap<Partial<FeatureStatus>>({
  // // PROD workspaces
  '47ce83c4-d983-48af-87cd-fda56faede18': {
    enabled: ['dashboard'],
    disabled: ['create-property'],
  }, // regus
  // 'b05dbdad-c3dc-432d-b635-3df345a09cfa': ['keepFloor'], // meero (meeronauts workspace)
  // 'a98482db-9c03-6078-d901-164266220734': ['uploadAlbum'], // meero demo sales real estate
  // 'bd93f6c6-1af3-4abe-85a0-c4272a4eb1d4': ['uploadAlbum'], // meero sales
  // 'e59ab0f1-82f2-4c7e-b5be-e249a6dd1fae': ['magicEraser'], // flatsy
  // 'aca8d3ae-a762-49f7-a687-99c5a27c236e': ['magicEraser'], // orpi-anthinea
  // '74f52935-ef58-46d2-ade0-bc4dc3d8a357': ['magicEraser'], // orpi france
  // // PREPROD workspaces
  // '0c442ac0-aec0-8794-b73b-2cf75f5f1e1d': ['homeStaging'], // Meero Realtors DO NOT USE since it is the same ID as in prod...
  // // STAGING workspaces
  // 'f2c77b21-ae2f-4cfd-93a2-dfad3f3a4adc': ['magicEraser'], // Real Estate Testing Staging Enhancement
});

export type WorkspaceFeatures = ReadonlySet<Feature>;
export function getEnabledFeatures(
  workspaceId: string | null
): WorkspaceFeatures {
  return new Set(
    workspaceId !== null
      ? workspaceFeatures.get(workspaceId)?.enabled
      : undefined
  );
}

export function getDisabledFeatures(
  workspaceId: string | null
): WorkspaceFeatures {
  return new Set(
    workspaceId !== null
      ? workspaceFeatures.get(workspaceId)?.disabled
      : undefined
  );
}
