import { SVGProps } from 'react';
import { colors } from '@/theme';

export const ProperShotLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 184 32"
      role="img"
      fill={colors.primary}
      {...props}
    >
      <path d="M31.4966 26.2804V4.30442H37.0406C38.1937 4.27721 39.3471 4.34679 40.4886 4.51242C41.2364 4.66706 41.9478 4.96302 42.5846 5.38442C43.5367 5.964 44.314 6.79077 44.8338 7.77682C45.3536 8.76288 45.5965 9.87136 45.5366 10.9844C45.5381 12.1518 45.264 13.303 44.7366 14.3444C44.24 15.3232 43.5124 16.1662 42.6166 16.8004C42.0144 17.2266 41.3263 17.516 40.6006 17.6484C39.4001 17.8141 38.188 17.881 36.9766 17.8484H35.5686V26.2484L31.4966 26.2804ZM35.5686 14.0724H36.8806C37.7992 14.1159 38.7186 14.0051 39.6006 13.7444C40.1518 13.5444 40.6234 13.1711 40.9446 12.6804C41.2913 12.1861 41.4758 11.5962 41.4726 10.9924C41.4797 10.4129 41.3022 9.84617 40.9656 9.37437C40.629 8.90258 40.1509 8.55023 39.6006 8.36842C38.7647 8.15612 37.9022 8.06718 37.0406 8.10442H35.5686V14.0724Z" />
      <path d="M47.5685 26.2804V9.77641H51.2085V11.2564C51.6303 10.6221 52.1742 10.0782 52.8085 9.65641C53.4062 9.34986 54.0733 9.20377 54.7445 9.23241H55.0725V13.0964C54.2092 13.1096 53.371 13.389 52.6725 13.8964C52.2433 14.3036 51.9134 14.8038 51.7081 15.3586C51.5028 15.9134 51.4277 16.508 51.4885 17.0964V26.2804H47.5685Z" />
      <path d="M55.7443 17.8804C55.7387 16.7396 55.9689 15.61 56.4204 14.5623C56.8719 13.5147 57.5351 12.5717 58.3683 11.7924C59.176 10.9696 60.1414 10.318 61.2067 9.87679C62.272 9.43556 63.4153 9.21368 64.5683 9.22441C65.719 9.20254 66.8619 9.41784 67.9259 9.85689C68.9898 10.2959 69.9519 10.9494 70.7523 11.7764C72.3919 13.4395 73.3111 15.681 73.3111 18.0164C73.3111 20.3518 72.3919 22.5934 70.7523 24.2564C69.9422 25.09 68.9698 25.7487 67.8952 26.1917C66.8205 26.6348 65.6665 26.8528 64.5043 26.8324C63.3501 26.8487 62.2053 26.6236 61.1432 26.1718C60.081 25.7199 59.125 25.0512 58.3363 24.2084C57.5005 23.3824 56.8401 22.3962 56.3947 21.3088C55.9494 20.2215 55.7281 19.0554 55.7443 17.8804ZM59.7443 17.9764C59.7081 19.3491 60.2106 20.6815 61.1443 21.6884C61.5815 22.1582 62.1108 22.5329 62.6992 22.7891C63.2876 23.0452 63.9225 23.1775 64.5643 23.1775C65.206 23.1775 65.8409 23.0452 66.4293 22.7891C67.0177 22.5329 67.5471 22.1582 67.9843 21.6884C68.8861 20.7062 69.3734 19.4135 69.3443 18.0804C69.3669 16.7166 68.8592 15.3973 67.9283 14.4004C67.5097 13.9139 66.9902 13.5244 66.4057 13.2591C65.8213 12.9938 65.1861 12.8591 64.5443 12.8644C63.9079 12.8709 63.28 13.0109 62.7011 13.2755C62.1223 13.5401 61.6056 13.9234 61.1843 14.4004C60.7203 14.8721 60.3542 15.4308 60.107 16.0446C59.8599 16.6583 59.7366 17.3148 59.7443 17.9764Z" />
      <path d="M75.745 31.7844V9.77641H79.385V11.7124C80.0289 10.9015 80.8548 10.2536 81.7958 9.82135C82.7367 9.3891 83.7663 9.18459 84.801 9.22441C85.9013 9.20057 86.9936 9.41649 88.0021 9.85718C89.0106 10.2979 89.9111 10.9528 90.641 11.7764C92.2132 13.4418 93.0741 15.6544 93.041 17.9444C93.0983 20.2767 92.2363 22.5381 90.641 24.2404C89.9101 25.0678 89.0093 25.7276 88 26.175C86.9908 26.6223 85.8969 26.8465 84.793 26.8324C83.8785 26.857 82.968 26.7021 82.113 26.3764C81.2286 25.982 80.4172 25.4411 79.713 24.7764V31.8244L75.745 31.7844ZM79.489 17.9764C79.4514 19.345 79.9445 20.675 80.865 21.6884C81.2968 22.1617 81.8226 22.5397 82.4087 22.7983C82.9948 23.0569 83.6284 23.1905 84.269 23.1905C84.9097 23.1905 85.5433 23.0569 86.1294 22.7983C86.7155 22.5397 87.2412 22.1617 87.673 21.6884C88.5921 20.7018 89.0913 19.3965 89.065 18.0484C89.096 16.6906 88.6033 15.3728 87.689 14.3684C87.272 13.8906 86.7565 13.5087 86.1778 13.2491C85.5992 12.9894 84.9712 12.8582 84.337 12.8644C83.6909 12.854 83.0499 12.9814 82.4568 13.2379C81.8636 13.4945 81.3319 13.8744 80.897 14.3524C79.9657 15.3259 79.4592 16.6295 79.489 17.9764Z" />
      <path d="M98.8008 19.7844C99.0742 20.7546 99.6529 21.6109 100.451 22.2262C101.25 22.8415 102.225 23.1832 103.233 23.2004C103.988 23.2216 104.735 23.0332 105.39 22.6562C106.045 22.2792 106.583 21.7283 106.945 21.0644H111.305C110.795 22.7376 109.739 24.1913 108.305 25.1924C106.877 26.2481 105.144 26.8098 103.369 26.7924C102.223 26.8108 101.086 26.5938 100.028 26.1549C98.9698 25.7159 98.0129 25.0643 97.2168 24.2404C96.3943 23.4274 95.7455 22.4558 95.3096 21.3846C94.8736 20.3133 94.6597 19.1648 94.6808 18.0084C94.6598 16.839 94.8733 15.6772 95.3089 14.5917C95.7445 13.5061 96.3932 12.519 97.2168 11.6884C98.0054 10.8509 98.9595 10.1866 100.019 9.73765C101.078 9.28868 102.219 9.06486 103.369 9.08042C104.49 9.08043 105.599 9.31128 106.627 9.75859C107.655 10.2059 108.58 10.8601 109.345 11.6804C110.155 12.4748 110.797 13.4253 111.23 14.4744C111.663 15.5235 111.879 16.6495 111.865 17.7844C111.846 18.4561 111.771 19.1251 111.641 19.7844H98.8008ZM98.7048 16.5844H107.985C107.754 15.4911 107.166 14.5059 106.313 13.7844C105.487 13.0922 104.438 12.7227 103.361 12.7444C102.272 12.7281 101.213 13.1028 100.377 13.8004C99.5154 14.5148 98.9252 15.5032 98.7048 16.6004V16.5844Z" />
      <path d="M114.289 26.2804V9.77642H117.929V11.2564C118.359 10.6158 118.918 10.071 119.569 9.65642C120.166 9.34883 120.834 9.20267 121.505 9.23242H121.833V13.0964C120.97 13.1126 120.133 13.3917 119.433 13.8964C119.005 14.3043 118.677 14.8049 118.473 15.3597C118.269 15.9145 118.195 16.5086 118.257 17.0964V26.2804H114.289Z" />
      <path d="M136.194 10.1044H132.002C131.975 9.43983 131.711 8.80676 131.258 8.32042C131.035 8.10445 130.77 7.93629 130.48 7.82624C130.189 7.71619 129.88 7.66657 129.57 7.68042C129.267 7.66393 128.964 7.70934 128.679 7.81388C128.394 7.91841 128.134 8.07988 127.914 8.28842C127.711 8.50097 127.552 8.75206 127.448 9.02687C127.343 9.30168 127.295 9.59464 127.306 9.88842C127.298 10.2254 127.371 10.5593 127.52 10.8616C127.669 11.1639 127.89 11.4256 128.162 11.6244C129.015 12.2109 129.954 12.6616 130.946 12.9604C132.324 13.3869 133.606 14.077 134.722 14.9924C135.367 15.5751 135.879 16.2895 136.224 17.0872C136.569 17.8849 136.739 18.7474 136.722 19.6164C136.743 20.5298 136.577 21.4379 136.233 22.2843C135.889 23.1307 135.375 23.8972 134.722 24.5364C134.057 25.2224 133.261 25.7678 132.382 26.1402C131.502 26.5127 130.557 26.7045 129.602 26.7044C128.495 26.7041 127.402 26.4582 126.402 25.9844C125.422 25.5308 124.563 24.8508 123.898 24.0004C123.016 22.8473 122.51 21.4508 122.45 20.0004H126.714C126.707 20.398 126.779 20.7929 126.926 21.1624C127.073 21.5318 127.292 21.8683 127.57 22.1524C127.859 22.4243 128.2 22.6353 128.572 22.7727C128.945 22.9102 129.341 22.9713 129.738 22.9524C130.519 22.9702 131.276 22.6833 131.85 22.1524C132.125 21.9023 132.343 21.5953 132.488 21.2528C132.633 20.9102 132.702 20.5402 132.69 20.1684C132.719 19.4661 132.479 18.7791 132.018 18.2484C131.359 17.6339 130.568 17.1794 129.706 16.9204C128.106 16.3284 127.05 15.9124 126.506 15.6564C125.963 15.3835 125.469 15.0241 125.042 14.5924C124.439 14.0126 123.965 13.313 123.649 12.5386C123.334 11.7642 123.184 10.9322 123.21 10.0964C123.201 9.27176 123.367 8.45456 123.695 7.6979C124.023 6.94124 124.506 6.26204 125.114 5.70442C126.009 4.81334 127.145 4.20338 128.383 3.94955C129.62 3.69573 130.905 3.80911 132.079 4.27576C133.252 4.7424 134.264 5.54196 134.99 6.5761C135.715 7.61023 136.123 8.83384 136.162 10.0964L136.194 10.1044Z" />
      <path d="M139.25 26.2804V4.30442H143.25V10.9284C144.389 9.81749 145.923 9.20455 147.514 9.22442C148.519 9.23322 149.507 9.48578 150.394 9.96042C151.31 10.4093 152.099 11.0803 152.69 11.9124C153.49 13.0244 153.906 14.7124 153.906 16.9604V26.2804H149.906V17.8484C150.003 16.5342 149.726 15.2193 149.106 14.0564C148.82 13.6553 148.436 13.3348 147.99 13.126C147.545 12.9171 147.053 12.827 146.562 12.8644C145.994 12.8449 145.433 12.9891 144.945 13.2799C144.457 13.5707 144.063 13.9958 143.81 14.5044C143.344 15.5771 143.141 16.7455 143.218 17.9124V26.2804H139.25Z" />
      <path d="M156.169 17.8804C156.162 16.7394 156.392 15.6093 156.843 14.5615C157.295 13.5136 157.959 12.5708 158.793 11.7924C159.601 10.9696 160.566 10.318 161.632 9.87679C162.697 9.43556 163.84 9.21367 164.993 9.22441C166.144 9.2035 167.287 9.41923 168.35 9.85822C169.414 10.2972 170.376 10.9501 171.177 11.7764C172.817 13.4395 173.736 15.681 173.736 18.0164C173.736 20.3518 172.817 22.5934 171.177 24.2564C170.367 25.09 169.395 25.7487 168.32 26.1917C167.246 26.6348 166.091 26.8528 164.929 26.8324C163.775 26.8486 162.63 26.6236 161.568 26.1718C160.506 25.7199 159.55 25.0512 158.761 24.2084C157.926 23.3824 157.265 22.3962 156.82 21.3088C156.374 20.2215 156.153 19.0553 156.169 17.8804ZM160.169 17.9764C160.133 19.3491 160.636 20.6815 161.569 21.6884C162.006 22.158 162.534 22.5325 163.122 22.7886C163.71 23.0447 164.344 23.1769 164.985 23.1769C165.626 23.1769 166.261 23.0447 166.848 22.7886C167.436 22.5325 167.965 22.158 168.401 21.6884C169.323 20.7038 169.82 19.3964 169.785 18.0484C169.808 16.6846 169.3 15.3653 168.369 14.3684C167.952 13.8829 167.433 13.494 166.85 13.2288C166.267 12.9636 165.634 12.8283 164.993 12.8324C164.346 12.8361 163.708 12.9779 163.12 13.2483C162.532 13.5187 162.009 13.9115 161.585 14.4004C161.12 14.8714 160.752 15.4299 160.504 16.0436C160.255 16.6574 160.131 17.3143 160.137 17.9764H160.169Z" />
      <path d="M176.545 26.2804V13.4164H174.609V9.77642H176.545V4.30442H180.545V9.77642H182.945V13.4164H180.545V26.2804H176.545Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9131 21.4796C10.4651 21.4796 14.7131 16.9516 12.0811 16.9516C9.60105 16.9516 5.83306 18.7676 3.28105 20.1516V24.1996C3.28672 24.7139 3.4737 25.2097 3.80905 25.5996C4.14548 25.9952 4.61496 26.2541 5.12905 26.3276H19.3051C19.8199 26.2563 20.2901 25.997 20.6251 25.5996C20.9662 25.2063 21.1562 24.7043 21.1611 24.1836V20.1036C18.8137 20.8697 16.3779 21.3321 13.9131 21.4796Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3687 15.4876L21.1527 13.0876V8.06361C21.1486 7.78864 21.0915 7.51703 20.9847 7.26361C20.88 6.99962 20.722 6.75999 20.5207 6.55961C20.3185 6.3586 20.0795 6.19836 19.8167 6.08761C19.5624 5.98468 19.2911 5.93041 19.0167 5.92761H15.2967C15.1732 5.92761 15.0508 5.95208 14.9367 5.99961C14.8198 6.04666 14.7156 6.12067 14.6327 6.21561L13.7847 5.27961C13.5817 5.06422 13.3366 4.89269 13.0647 4.77561C12.7965 4.66154 12.5083 4.60172 12.2167 4.59961C11.9226 4.60172 11.6318 4.66151 11.3607 4.77561C11.0913 4.89284 10.8489 5.06442 10.6487 5.27961L1.04874 15.4876C0.966507 15.5792 0.901467 15.6849 0.856742 15.7996C0.81426 15.9119 0.795203 16.0317 0.800742 16.1516C0.802615 16.2758 0.829839 16.3983 0.880742 16.5116C0.930344 16.6228 1.00097 16.7233 1.08874 16.8076C1.23841 16.9518 1.43355 17.0395 1.64074 17.0556C1.84963 17.0739 2.0585 17.0203 2.23274 16.9036L2.99274 16.4076L3.26474 16.2476C6.39274 14.6076 11.3847 12.3276 14.9127 12.3276C18.4407 12.3276 12.9767 16.9676 16.5527 16.9676C18.1098 16.9087 19.6518 16.6396 21.1367 16.1676L21.7047 16.5436C21.851 16.635 21.9926 16.7338 22.1287 16.8396C22.3011 16.9752 22.5182 17.0409 22.7367 17.0236C22.9509 17.0071 23.1536 16.9199 23.3127 16.7756C23.3987 16.6897 23.4691 16.5896 23.5207 16.4796C23.5716 16.3663 23.5989 16.2438 23.6007 16.1196C23.6063 15.9997 23.5872 15.8799 23.5447 15.7676C23.5016 15.6654 23.4422 15.5708 23.3687 15.4876Z"
      />
    </svg>
  );
};
